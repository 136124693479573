import React from 'react';
import styled from '@emotion/styled';

import colors from 'Utils/theme';
import * as v from 'Utils/variables';

import { responsiveFont } from 'Styles/helpers';

const HeaderWrapper = styled.div`
  margin: inherit;
  /* max-width: 90%; */
`;

const HeaderH2 = styled.h2`
  ${(props) => (props.inCta ? responsiveFont({ sizes: '32, 60' }) : responsiveFont({ sizes: '24, 36' }))};

  text-align: ${(props) => (props.inCta ? 'left' : 'center')};
  color: ${(props) => (props.inCta ? colors.white : colors.walmart.blue)};
  font-weight: 700;
  line-height: 1.17;
  letter-spacing: -0.1px;

  svg {
    height: 20px;
    width: auto;
    margin-right: 8px;
  }
`;

const SubHeader = styled.div`
  ${(props) => (props.inCta ? responsiveFont({ sizes: '16,24' }) : responsiveFont({ sizes: '16,19' }))};
  color: ${(props) => (props.inCta ? colors.white : colors.walmart.blue)};
  text-align: ${(props) => (props.inCta ? 'left' : 'center')};
  margin: ${(props) => (props.inCta ? (props.eligible !== undefined ? '15px 0 0' : '30px 0  0') : '30px 0')};

  sup {
    font-size: 0.444em;
    padding-left: 2px;
  }

  @media (min-width: ${v.minTablet}) {
    margin: ${(props) => (props.inCta ? (props.eligible !== undefined ? '20px 0 0' : '50px 0  0') : '30px 0')};
    max-width: 680px;
  }
`;

const Header = ({ headerCopy, inCta, eligible }) => {
  return (
    <HeaderWrapper inCta={inCta}>
      <HeaderH2 inCta={inCta} eligible={eligible}>
        {headerCopy.main}
      </HeaderH2>
      {headerCopy.subHeading && (
        <SubHeader inCta={inCta} eligible={eligible}>
          {headerCopy.subHeading}
        </SubHeader>
      )}
    </HeaderWrapper>
  );
};

export default Header;
