import { useState } from 'react';
import validation from 'Components/forms/validation';

const useFormInput = ({ initialValue = '', validators = [], useAutoFill = false, useAutoComplete = false, focusFunction = () => null }) => {
  const [value, setValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(initialValue !== '' && initialValue !== null && !touched ? true : null);
  const [touched, setTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);

  const validateField = (value) => {
    setIsEmpty(validation.isEmpty(value));

    if (validators && validators.length) {
      validators.some((validator) => {
        const result = validator(value);
        const valid = result === null;

        setIsValid(valid);
        setErrorMessage(result);

        return !valid;
      });
    }
  };

  if (useAutoComplete) {
    return {
      props: {
        touched,
        isValid,
        isEmpty,
        errorMessage,
        reset: () => setValue(initialValue),
        validate: () => setIsValid(true),
      },
      onBlur: (event) => validateField(event.target.value),
    };
  }

  if (useAutoFill) {
    return {
      touched,
      isValid,
      isEmpty,
      errorMessage,
      onFocus: () => setTouched(true) + focusFunction(),
      onBlur: (event) => validateField(event.target.value),
      reset: () => setValue(initialValue),
      validate: () => setIsValid(true),
    };
  }

  return {
    value,
    touched,
    isValid,
    isEmpty,
    errorMessage,
    onFocus: () => setTouched(true) + focusFunction(),
    onChange: (event) => setValue(event.target.value),
    onBlur: (event) => validateField(event.target.value),
    reset: () => {
      setValue(initialValue);
      setIsValid(true);
      setTouched(false);
    },
    validate: () => setIsValid(true),
    validateField: (value) => validateField(value),
  };
};

export default useFormInput;
