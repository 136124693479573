import { useState, useEffect } from 'react';

import { rollbarError } from 'Utils/rollbar';

const useFetch = (url, options, shouldFetch) => {
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jwtToken, setJwtToken] = useState();
  const [refetch, setRefetch] = useState(false);

  let fetchAttempts = 0;

  useEffect(() => {
    let mounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchUrl = async () => {
      setLoading(true);
      const res = await fetch(url, {
        signal: signal,
        ...options,
      }).catch((errors) => {
        setLoading(false);
        return setErrors([{ errors: errors }]);
      });

      if (!res || res === undefined) {
        if (fetchAttempts < 3) {
          fetchUrl();
          // eslint-disable-next-line no-console
          console.log(`Fetch failed(${fetchAttempts + 1}) - retrying`);
          fetchAttempts++;
          return;
        } else {
          setLoading(false);
          setErrors([{ errors: { message: 'There was a problem with the request and we are looking into it. Please try again later.' } }]);
          fetchAttempts = 0;
          rollbarError();
          return () => cleanup();
        }
      }

      if (res.status === 403 || res.status === 401 || (errors.length > 0 && (errors[0].errors.status === 401 || errors[0].errors.status === 403))) {
        window.location.assign(`${process.env.NEXT_PUBLIC_LOGOUT_URL}${window.location.pathname}`);
        return cleanup();
      }

      const success = await res.ok;
      const status = await res.status;
      const jwt = await res.headers.get('x-csrf-jwt');
      const data = await res.json();

      if (success) {
        setData(data);
        setJwtToken(jwt);
        setLoading(false);
        fetchAttempts = 0;
        setRefetch(false);
      } else {
        if (fetchAttempts < 3) {
          fetchUrl();
          // eslint-disable-next-line no-console
          console.log(`Fetch failed(${fetchAttempts}) - retrying`);
          fetchAttempts++;
        } else {
          setLoading(false);
          setErrors([{ errors: data.errors, status }]);
          fetchAttempts = 0;
          setRefetch(false);
        }
      }
    };

    const cleanup = () => {
      mounted = false;
      abortController.abort();
    };

    if (mounted) {
      if (shouldFetch || refetch) {
        fetchUrl();
      }
    }

    return () => cleanup();
  }, [url, shouldFetch, refetch]);
  return { data, errors, loading, jwtToken, refetch: () => setRefetch(true) };
};

export default useFetch;
