import React, { useState, useEffect } from 'react';
import NextLink from 'next/link';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { convertify, responsiveFont } from 'Styles/helpers';

import { minTablet, minDesktop, min1025 } from 'Utils/variables';
import colors from 'Utils/theme';

import useFocusMethod from 'Hooks/useFocusMethod';
import useWindowSize from 'Hooks/useWindowSize';

import Button from '../button';
import Container from '../container';

import Modal from 'Components/modal';
import AvailabilityChecker from 'Components/availability-checker';

const Root = styled.header`
  position: absolute;
  display: block;

  margin-top: ${(props) => (props.isPartner ? 0 : '40px')};
  width: 100%;
  z-index: 665;

  background-color: ${(props) => (props.isPartner ? colors.inHome.blue : colors.white)};

  @media (min-width: ${minDesktop}) {
    margin-top: 0;
  }

  &.fixed {
    position: fixed;
    height: 80px;

    margin-top: 0;

    background-color: ${colors.white};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

    transition: background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  }
`;

const HeaderContainer = styled(Container)`
  position: relative;
  height: 100%;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isPartner ? 'center' : 'flex-start')};

  height: 100%;

  &.fixed {
    justify-content: flex-start;
  }

  svg {
    display: block;

    width: 119px;
    height: auto;

    ${(props) =>
      !props.isPartner &&
      css`
        transition: all 0.2s ease-in-out;
      `};
  }

  @media (min-width: ${minTablet}) {
    svg {
      height: auto;
      width: 160px;
    }

    &.fixed {
      svg {
        width: 119px;
        height: auto;
      }
    }
  }

  @media (min-width: ${minDesktop}) {
    margin-left: ${convertify(1)};
    margin-right: ${convertify(1)};

    svg {
      height: auto;
      width: 238px;
    }

    &.fixed {
      svg {
        width: 119px;
        height: auto;
      }
    }
  }
`;

const LogoWrap = styled.div`
  width: auto;
  position: ${(props) => (props.isPartner ? 'absolute' : 'relative')};
  top: ${(props) => (props.isPartner ? '40px' : 'inherit')};

  &.fixed {
    top: ${(props) => (props.isPartner ? '28px' : 'inherit')};
  }

  @media (min-width: ${minTablet}) {
    position: absolute;
    top: ${(props) => (props.isPartner ? '57px' : '0')};
    z-index: 10;
    width: auto;

    &.fixed {
      top: ${(props) => (props.isPartner ? 28 : 23)}px;
    }
  }

  @media (min-width: ${minDesktop}) {
    top: ${(props) => (props.isPartner ? '87px' : '137px')};

    &.fixed {
      top: ${(props) => (props.isPartner ? 28 : 23)}px;
    }
  }

  @media (min-width: ${minDesktop}) and (max-height: 922px) {
    top: 87px;

    &.fixed {
      top: ${(props) => (props.isPartner ? 28 : 23)}px;
    }
  }
`;

const Label = styled.span`
  display: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: right;

  color: ${colors.inHome.darkGrey};

  @media (min-width: ${minTablet}) {
    display: inline;
    padding-left: 20px;
  }
`;

const Navigation = styled.div`
  opacity: 0;
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;

  margin-left: auto;

  height: 100%;

  pointer-events: none;
  transition: opacity 0.25s ease-in-out;

  &.fixed {
    opacity: 1;
    pointer-events: auto;
  }
`;

const HeaderButton = styled(Button)`
  padding: 0 20px;

  &:first-of-type {
    margin-right: 0;
    margin-left: 25px;
  }

  width: auto;
  max-width: none;

  @media (min-width: ${minTablet}) {
    padding: 0 35px;
  }

  @media (min-width: ${min1025}) {
    max-width: none;
  }
`;

const ModalContent = styled.div`
  padding: 30px;

  h5 {
    ${responsiveFont({ sizes: '20,24' })};
    line-height: ${34 / 24};
  }

  @media (min-width: ${minTablet}) {
    padding: 60px;
  }
`;

const HeaderStandalone = ({ logo, isPartner }) => {
  const [isFixed, setFixed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useFocusMethod();

  const { windowWidth } = useWindowSize();

  const handleScroll = () => {
    const logoOffset = window.matchMedia(`(min-width: ${minDesktop})`).matches ? 104 : 23;
    setFixed(window.pageYOffset > logoOffset);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const closeModalFunction = () => {
    setShowModal(false);
  };

  // const handleClick = () => {
  //   setShowModal(true);
  // };

  return (
    <>
      <Root className={isFixed ? 'fixed' : ''} isPartner={isPartner}>
        <HeaderContainer>
          <Content className={isFixed ? 'fixed' : ''} isPartner={isPartner}>
            <LogoWrap className={isFixed ? 'fixed' : ''} isPartner={isPartner}>
              <NextLink href="/">
                <a>{logo({ isFixed: isFixed })}</a>
              </NextLink>
            </LogoWrap>

            {windowWidth > 720 && (
              <Navigation className={isFixed ? 'fixed' : ''}>
                {isPartner ? (
                  <HeaderButton isPartner={isPartner} href="/get-started">
                    Start 30-day free trial
                  </HeaderButton>
                ) : (
                  <>
                    <Label>Start your 30-day free trial.</Label>
                    <HeaderButton href="/get-started">Get InHome</HeaderButton>
                  </>
                )}
              </Navigation>
            )}
          </Content>
        </HeaderContainer>
      </Root>
      <Modal
        displayTriggerComponent={false}
        openModalCall={showModal}
        content={() => (
          <ModalContent>
            <AvailabilityChecker />
          </ModalContent>
        )}
        onClose={() => closeModalFunction()}
      />
    </>
  );
};

export default HeaderStandalone;
