import { useEffect, useContext } from 'react';
import { StateContext } from 'Context/StateContext';
import { DispatchContext } from 'Context/DispatchContext';
import { defaultFetch } from 'Api/defaultFetch';
import { DefaultContext } from 'Context/DefaultContext';

const useFeatureFlag = () => {
  const { dispatch } = useContext(DispatchContext);
  const { inHomeBaseApiUrl } = useContext(DefaultContext);
  const { featureFlagState } = useContext(StateContext);

  useEffect(() => {
    if (!featureFlagState?.hasLoaded) {
      defaultFetch(`${inHomeBaseApiUrl}/feature_flag`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'x-customer-version': '100.100.100',
        },
        method: 'GET',
      }).then(({ data }) => {
        dispatch({ type: 'SET_FEATURE_FLAGS', payload: data?.flags });
        dispatch({ type: 'SET_HAS_LOADED', payload: true });
      });
    }
  }, []);
};
export default useFeatureFlag;