import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';

import { DefaultContext } from 'Context/DefaultContext';
import { StateContext } from 'context/StateContext';
import { DispatchContext } from 'context/DispatchContext';

import { max1025 } from 'Utils/variables';
import colors from 'Utils/theme';

import useWindowSize from 'Hooks/useWindowSize';
import useFetch from 'Hooks/useFetch';

import AddressForm from 'Components/checkout/AddressForm';
import LoadingSpinner from 'Components/loadingSpinner';

import AddressesSelect from './AddressesSelect';

import Plus from 'Icons/Plus';

const StyledFormWrapper = styled.div`
  padding: 30px 0;
  margin: 0 0 20px;
  max-width: 745px;
  background-color: ${colors.white};

  @media (min-width: ${max1025}) {
    padding: 30px 25px;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media (min-width: ${max1025}) {
      flex-direction: row;
    }
  }

  @media (min-width: ${max1025}) {
    border: 1px solid ${(props) => (props.inCta ? 'transparent' : colors.inHome.medGrey)};
    border-radius: 18px;
  }
`;

const StyledAddNewButton = styled.button`
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${colors.inHome.medGrey};
  border-radius: 14px;
  padding: 20px 0;
  margin: 20px 0;
  width: 100%;
  line-height: 1em;
  font-size: 15px;
  cursor: pointer;

  svg {
    width: 12px;
    height: auto;
    margin-left: 10px;
  }
`;

const Addresses = ({ leadCapture, storedAddress, setSoftErrors, inCta, selectFunction }) => {
  const { inHomeBaseApiUrl } = useContext(DefaultContext);
  const { dispatch } = useContext(DispatchContext);
  const { userState, addressesState, checkoutState } = useContext(StateContext);

  const { user } = userState;
  const { addresses, selectedAddressId } = addressesState;
  const { selectedAddress } = checkoutState;

  // Fetch data
  const { data, errors, loading } = useFetch(
    `${inHomeBaseApiUrl}/users/CID/address`,
    {
      credentials: 'include',
      headers: {
        'x-customer-version': '100.100.100',
      },
      method: 'GET',
    },
    !addresses,
  );

  useEffect(() => {
    if (data) {
      dispatch({ type: 'UPDATE_ADDRESSES', payload: data.addresses });
    }
  }, [data]);

  const [addNewAddress, setAddNewAddress] = useState(false);

  useEffect(() => {
    if (addresses) {
      const eligible = addresses.find((address) => address.eligible === true);
      eligible && dispatch({ type: 'SET_SELECTED_ADDRESS_ID', payload: eligible.id });
    }
  }, [addresses]);

  const { windowWidth } = useWindowSize();

  const currentAddress = addresses?.find((address) => address.id === selectedAddressId);
  const partnership = currentAddress?.partnership;
  const isHomebase = partnership === 'homebase';

  if (loading) {
    return (
      <div style={{ padding: '40px 0' }}>
        <LoadingSpinner color={colors.inHome.blue} />
      </div>
    );
  }

  if (!loading && !addresses && errors?.length > 0) return null;

  return (
    <>
      {user && addresses && addresses.length > 0 && !addNewAddress && <AddressesSelect openModal={(postalCode) => leadCapture(postalCode)} setAddNewAddress={(bool) => setAddNewAddress(bool)} addresses={addresses} selectFunction={selectFunction} inCta={inCta} />}

      {user && addresses && (addresses.length === 0 || addNewAddress) && !inCta && (
        <StyledFormWrapper>
          <AddressForm user={user} storedAddress={storedAddress && storedAddress.eligible && storedAddress} addresses={addresses} cancelFunction={() => setAddNewAddress(false)} setSoftErrors={setSoftErrors} ineligibleModalFunction={(postalCode) => leadCapture(postalCode)} />
        </StyledFormWrapper>
      )}

      {partnership && !inCta && (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '40px', padding: '0 20px' }}>
          <strong>{isHomebase ? 'Not a Homebase user?' : 'Not a Lennar + Level homeowner?'}</strong>
          <span>
            Contact InHome Customer Care at <a href="tel:833-235-2111">833-235-2111</a> to finish signing up.
          </span>
        </div>
      )}

      {!addNewAddress && windowWidth < 720 && (!selectedAddress && (user && addresses && addresses.length !== 0)) && (
        <StyledAddNewButton
          tabIndex="0"
          role="button"
          onClick={() => {
            setAddNewAddress(true);
            sessionStorage.removeItem('address');
          }}
        >
          Add new address <Plus fill={colors.walmart.blue} />
        </StyledAddNewButton>
      )}
    </>
  );
};

export default Addresses;
