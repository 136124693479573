import React from 'react';
import colors from 'Utils/theme';

const Chevron = ({ fill = colors.inHome.blue, className }) => {
  return (
    <svg fill={fill} className={className} viewBox="0 0 48 48">
      <path
        d="M1.2,14.1c0-1.8,1.5-3.3,3.3-3.3c0.9,0,1.7,0.4,2.3,1L24,29.2l17.2-17.4c1.3-1.2,3.4-1.1,4.7,0.2c1.1,1.2,1.1,3.1,0,4.4
   L26.4,36.2c-1.3,1.3-3.4,1.3-4.7,0c0,0,0,0,0,0L2.1,16.4C1.5,15.8,1.2,15,1.2,14.1z"
      />
    </svg>
  );
};

export default Chevron;
