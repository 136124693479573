import React from 'react';
import Head from 'next/head';

const headPreload = () => (
  <Head>
    <link rel="preload" href="/static/fonts/BogleWeb-Bold.woff" as="font" crossOrigin="anonymous" />
    <link rel="preload" href="/static/fonts/BogleWeb-Bold.woff2" as="font" crossOrigin="anonymous" />
    <link rel="preload" href="/static/fonts/BogleWeb-Regular.woff" as="font" crossOrigin="anonymous" />
    <link rel="preload" href="/static/fonts/BogleWeb-Regular.woff2" as="font" crossOrigin="anonymous" />
    <link rel="preload" href="/static/fonts/BogleWeb-RegularItalic.woff" as="font" crossOrigin="anonymous" />
    <link rel="preload" href="/static/fonts/BogleWeb-RegularItalic.woff2" as="font" crossOrigin="anonymous" />

    <meta charSet="utf-8" />
    <meta httpEquiv="x-ua-compatible" content="ie=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@walmart" />
    <meta property="og:type" content="website" />

    <link rel="icon" type="image/x-icon" href="/static/favicon.ico" />
    <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon-16x16.png" />
    <link rel="apple-touch-icon" sizes="180x180" href="/static/apple-touch-icon.png" />
    <link rel="manifest" href="/static/site.webmanifest" crossOrigin="use-credentials" />

    <meta name="google-site-verification" content="ykcy-0ntZmEDjzR9HLe3QTVTq9EjBlIocEQ-0B2kFGY" />

    <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places`} />
  </Head>
);

export default headPreload;
