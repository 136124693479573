import React from 'react';
import styled from '@emotion/styled';

import colors from 'Utils/theme';

const minTabletBreakpoint = 1280;

const Wrapper = styled.button`
  display: block;

  position: relative;
  z-index: 10;

  padding: 10px;
  margin: -10px;
  margin-left: auto;

  cursor: pointer;

  transform: rotate(${(props) => (props.isActive ? '45deg' : '0deg')});
  transition: transform 200ms ease-in-out;

  @media (min-width: ${minTabletBreakpoint}px) {
    display: none;
  }
`;

const Line = styled.div`
  display: block;

  width: 20px;
  height: 2px;

  border-radius: 2px;

  background-color: ${(props) => props.color};

  transition-property: transform, width;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;

  &:nth-of-type(1),
  &:nth-of-type(2) {
    margin-bottom: 5px;
  }

  &:nth-of-type(1) {
    transform: ${(props) => (props.isActive ? 'translateY(7px)' : null)};
  }

  &:nth-of-type(2) {
    opacity: ${(props) => (props.isActive ? '0' : '1')};
  }

  &:nth-of-type(3) {
    transform: ${(props) => (props.isActive ? 'translateY(-7px) rotate(90deg)' : null)};
  }
`;

const Hamburger = ({ isActive, onClick, className, color = colors.walmart.blue, ...props }) => (
  <Wrapper isActive={isActive} onClick={onClick} className={className} {...props}>
    <Line color={color} isActive={isActive} />
    <Line color={color} isActive={isActive} />
    <Line color={color} isActive={isActive} />
  </Wrapper>
);

export default Hamburger;
