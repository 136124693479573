import React from 'react';
import styled from '@emotion/styled';

import colors from 'Utils/theme';

const StyledLoadingSpinner = styled.div`
  display: flex;
  height: 40px;
  width: 40px;
  margin: 0 auto;

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid ${(props) => (props.color ? props.color : colors.walmart.blue)};
    border-top-color: transparent;
    animation: spinner 0.6s linear infinite;

    @keyframes spinner {
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

const LoadingSpinner = ({ color }) => {
  return <StyledLoadingSpinner color={color} />;
};

export default LoadingSpinner;
