const UserReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_HAS_INHOME':
      return {
        ...state,
        hasInHome: action.payload,
      };
    case 'SET_HAS_DEVICE':
      return {
        ...state,
        hasDevice: action.payload,
      };
    case 'CURRENT_HOME':
      return {
        ...state,
        currentHome: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
