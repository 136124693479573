import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { lighten } from 'polished';

import { min1025 } from 'Utils/variables';
import colors from 'Utils/theme';
import { logEvent } from 'Utils/analytics';

import { responsiveFont } from 'Styles/helpers';

import NextLink from 'Components/link';

import { Button as LDButton } from '@walmart-web/livingdesign-components';

const button = (props) => css`
  ${props.big ? responsiveFont({ sizes: '18,18' }) : responsiveFont({ sizes: '16,16' })}

  display: block;

  padding-left: 35px;
  padding-right: 35px;

  width: 100%;
  max-width: 160px;

  border: ${props.outline ? '1px solid #e5e5e5' : '2px solid transparent'};
  border-radius: 30px;

  cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
  white-space: nowrap;
  user-select: none;

  line-height: 44px;
  text-align: center;
  text-decoration: none;

  color: ${props.isBlue ? colors.white : colors.walmart.blue};
  background-color: ${props.outline ? colors.white : props.isBlue ? colors.inhome.blue : colors.walmart.yellow};
  opacity: ${props.disabled ? 0.6 : 1};

  transition: background-color 200ms ease-in-out;

  &:hover {
    color: ${props.isBlue ? colors.white : colors.walmart.blue};
    background-color: ${props.isBlue ? '#1D4896' : lighten(props.disabled ? 0 : 0.1, colors.walmart.yellow)};
  }

  &:active {
    color: ${props.isBlue ? colors.white : colors.walmart.blue};
    background-color: ${props.isBlue ? '#041E42' : lighten(props.disabled ? 0 : 0.1, colors.walmart.yellow)};
  }

  @media (min-width: ${min1025}) {
    padding-left: 35px;
    padding-right: 35px;

    line-height: 50px;
  }
`;

const link = css`
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;

const Link = styled(NextLink)`
  ${button}
  ${link}
`;

const Button = ({ href, children, className, targetBlank = true, track = false, trackId, ...rest }) => {
  const passProps = { ...rest };
  const isLink = typeof href !== 'undefined';
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(href || '');

  const handleClick = () => {
    if (track) {
      const id = trackId || `"${String(children)}" clicked`;

      logEvent('Navigation', id);
    }

    if (passProps.onClick) {
      passProps.onClick();
    }
  };

  if (isExternal) {
    return (
      <Link asLDButton title={children} className={className} target={targetBlank ? '_blank' : undefined} rel="noopener noreferrer" href={href} onClick={handleClick} {...passProps}>
        {children}
      </Link>
    );
  }

  if (isLink) {
    return (
      <Link asLDButton title={children} className={className} href={href || '#'} as={href || '#'} trackId={trackId || `"${String(children)}" clicked`} track {...passProps}>
        {children}
      </Link>
    );
  }

  return (
    <LDButton title={children} className={className} onClick={handleClick} {...passProps}>
      {children}
    </LDButton>
  );
};

export default Button;
