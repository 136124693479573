import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { DefaultContext } from 'Context/DefaultContext';

import Chat from 'Icons/Chat';

import colors from 'Utils/theme';
import { gaLogEvent } from 'Utils/analytics';

import Link from 'components/link';

const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${colors.inHome.darkGrey};
  cursor: pointer;
  white-space: nowrap;

  svg {
    width: 20px;
    height: auto;
    margin-right: 8px;
  }
`;

const ChatLink = ({ children }) => {
  const { walmartURL } = useContext(DefaultContext);

  return (
    <Wrapper
      href={`${walmartURL}/help?chatBot=open`}
      title={children}
      clickFunction={() => {
        gaLogEvent('chat', 'initiated chat');
      }}
    >
      <Chat /> {children}
    </Wrapper>
  );
};

export default ChatLink;
