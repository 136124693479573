import React from 'react';

import colors from 'Utils/theme';

const Chat = ({ fill = colors.inHome.blue }) => {
  return (
    <svg fill={fill} viewBox="0 0 40.84 42.51">
      <path
        d="M24,2.73A20.69,20.69,0,0,0,3.58,23.64,21,21,0,0,0,7.87,36.4L5.1,43a1.6,1.6,0,0,0,.21,1.61,1.53,1.53,0,0,0,1.2.59h.26L17.3,43.36A20.42,20.42,0,0,0,24,44.55,20.7,20.7,0,0,0,44.42,23.64,20.69,20.69,0,0,0,24,2.73Zm0,38.6a16.69,16.69,0,0,1-6-1.13,1.42,1.42,0,0,0-.84-.05L9,41.6l2-4.83a1.59,1.59,0,0,0-.26-1.66,17.85,17.85,0,0,1-2.93-5,18.21,18.21,0,0,1-1.21-6.44A17.51,17.51,0,0,1,23.9,6,17.51,17.51,0,0,1,41.17,23.69,17.37,17.37,0,0,1,24,41.33Z"
        transform="translate(-3.58 -2.73)"
      />
      <ellipse cx="12.78" cy="21.28" rx="2.3" ry="2.36" />
      <ellipse cx="20.42" cy="21.28" rx="2.3" ry="2.36" />
      <ellipse cx="28.07" cy="21.28" rx="2.3" ry="2.36" />
    </svg>
  );
};

export default Chat;
