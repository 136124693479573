import React from 'react';
import styled from '@emotion/styled';

import colors from 'Utils/theme';

const New = styled.span`
  display: inline-block;
  position: relative;
  top: ${(props) => (props.top ? props.top : 0)};
  padding: 0 8px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)};

  color: ${colors.walmart.blue};
  background-color: ${(props) => (props.primary ? colors.walmart.yellow : '#E8EFF6')};
  border-radius: 24px;

  font-size: 10px;

  font-weight: 500;
  line-height: 19px;
  text-transform: uppercase;
  white-space: nowrap;
`;

const Badge = ({ children, marginLeft, marginRight, top, primary = true }) => (
  <New marginLeft={marginLeft} marginRight={marginRight} primary={primary} top={top}>
    {children}
  </New>
);

export default Badge;
