import React, { useContext } from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';

import { StateContext } from 'Context/StateContext';

import colors from 'Utils/theme';
import * as v from 'Utils/variables';

import Row from 'Components/row';
import Addresses from 'Components/addresses';

import { responsiveFont } from 'Styles/helpers';

const AddressToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.inCta &&
    css`
      margin-top: 16px;
    `};

  @media (min-width: ${v.minTablet}) {
    justify-content: flex-start;
  }
`;

const ToggleLink = styled.button`
  ${(props) => (props.inCta ? responsiveFont({ sizes: '16,16' }) : responsiveFont({ sizes: '18,22' }))};
  -webkit-appearance: none;
  padding-bottom: ${(props) => (props.inCta ? '5px' : '10px')};
  outline: none;
  color: ${(props) => (props.disabled ? colors.inHome.darkGrey : props.inCta ? colors.white : colors.walmart.blue)};
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  border-bottom: 2px solid ${(props) => (props.active ? colors.walmart.yellow : 'transparent')};
  margin-right: 20px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: color 300ms;

  &:last-of-type {
    margin-right: 0;
  }
`;

const CardWrapper = styled.div`
  margin-top: 47px;

  ${(props) =>
    props.inCta &&
    css`
      margin-top: 20px;

      @media (min-width: ${v.max1025}) {
        max-height: 320px;
        overflow-y: auto;
      }

      @media (min-width: ${v.min1440}) {
        max-height: 300px;
      }
    `}
`;

const AddressesToggle = ({ addressBook, setAddressBook, inCta, setAddNewAddress, getAvailableDates }) => {
  const { addressesState } = useContext(StateContext);

  const { addresses } = addressesState;

  const disabled = !addresses || (addresses && addresses.length === 0);

  return (
    <AddressToggleWrapper>
      <ToggleWrapper inCta={inCta}>
        <ToggleLink
          active={addressBook}
          disabled={disabled}
          inCta={inCta}
          onClick={(e) => {
            e.preventDefault();
            setAddressBook(true);
          }}
        >
          My address book
        </ToggleLink>
        <ToggleLink
          active={!addressBook}
          inCta={inCta}
          onClick={(e) => {
            e.preventDefault();
            setAddressBook(false);
          }}
        >
          Check a new address
        </ToggleLink>
      </ToggleWrapper>

      {addressBook && (addresses && addresses.length > 0) && (
        <CardWrapper inCta={inCta}>
          <Addresses
            isInDrawer={true}
            inCta={inCta}
            setAddNewAddress={(bool) => {
              setAddNewAddress(bool);
              setAddressBook(false);
            }}
            addresses={addresses}
            selectFunction={(id) => getAvailableDates(addresses.find((address) => address.id === id))}
          />
        </CardWrapper>
      )}
    </AddressToggleWrapper>
  );
};

export default AddressesToggle;
