import React, { useState, useEffect } from 'react';

import { getSlugs } from 'Utils';
import { jungroupEvent } from 'Utils/analytics';

import DefaultProvider from 'Context/DefaultProvider';
import StoreProvider from 'Context/StoreProvider';
import { LivingDesignProvider } from '@walmart-web/livingdesign-components';

import Header from 'Containers/header';
import Footer from 'Containers/footer';
import HeaderStandalone from 'Containers/header-standalone';
import MainContent from 'Containers/mainContent';

const Providers = ({ children }) => {
  const [mounted, setMounted] = useState(false);

  const [isHomepage, setIsHomepage] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (mounted) {
      const path = window.location.pathname;

      setIsHomepage(path === '/');

      if (getSlugs(path)[0]) {
        if (getSlugs(path)[0].includes('get-started')) {
          jungroupEvent('landing_page');
        }
      }
    }
  }, [mounted]);

  const isStandalone = ['landing-grocery', 'landing-lennar', 'landing-returns', 'landing-pharmacy', 'landing-grocery-delivery', 'zip-code', 'coming-soon'];
  const isHeaderLessAndFooterLess = ['checkout', 'scheduling', 'scheduling-mobile', 'what-makes-us-different', 'returns-mobile', 'reactivate', 'update-credit-card', 'chat', 'landing-field-marketing', 'device-paywall', 'keypad'];
  const isHeaderLessOnly = ['device-selection', 'partnership'];

  if (!mounted) return null;

  return (
    <StoreProvider>
      <DefaultProvider>
        <LivingDesignProvider>
          {!isStandalone.includes(getSlugs(window.location.pathname)[0]) && <Header style={{ display: !isHomepage && !isHeaderLessAndFooterLess.includes(getSlugs(window.location.pathname)[0]) && !isHeaderLessOnly.includes(getSlugs(window.location.pathname)[0]) ? 'block' : 'none' }} />}
          {isStandalone.includes(getSlugs(window.location.pathname)[0]) && <HeaderStandalone pathname={window.location.pathname} />}
          <MainContent>{children}</MainContent>
          <Footer style={{ display: !isHomepage && !isHeaderLessAndFooterLess.includes(getSlugs(window.location.pathname)[0]) ? 'block' : 'none' }} />
        </LivingDesignProvider>
      </DefaultProvider>
    </StoreProvider>
  );
};

export default Providers;
