// Trap focus within a container
export function trapFocus(e, keys, containerRef) {
  if (!containerRef.current && !document) {
    return;
  }

  const parentElements = [...containerRef.current.parentNode.querySelectorAll('a[href]:not([disabled]), area[href]:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]')];
  const elements = [...containerRef.current.querySelectorAll('a[href]:not([disabled]), area[href]:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]')]; // All focusable elements in container
  const firstFocusableEl = elements[0];
  const lastFocusableEl = elements[elements.length - 1];

  if (keys && !keys.includes(13) && elements.length === 0 && parentElements.length !== 0) {
    e.preventDefault();
    parentElements[0].focus();
  } else if (keys && keys.includes(9) && keys.includes(16) && document.activeElement === firstFocusableEl) {
    // Backward TAB
    e.preventDefault();
    lastFocusableEl.focus();
  } else if (keys && keys.includes(9) && !keys.includes(16) && document.activeElement === lastFocusableEl) {
    // Forward TAB
    e.preventDefault();
    if (parentElements.length !== 0) {
      parentElements[0].focus();
    } else {
      firstFocusableEl.focus();
    }
  }
}
