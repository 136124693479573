import styled from '@emotion/styled';
import { math } from 'polished';

import { gutter } from 'Utils/variables';

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;

  margin-left: ${math(`-${gutter} / 2`)};
  margin-right: ${math(`-${gutter} / 2`)};
`;
