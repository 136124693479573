import React from 'react';
import colors from 'Utils/theme';

const Plus = ({ fill = colors.white }) => {
  return (
    <svg className="plus" viewBox="0 0 46 46" fill={fill}>
      <path
        d="M22.38,22.38V2.62a1.62,1.62,0,0,1,.46-1.16,1.69,1.69,0,0,1,2.32,0,1.62,1.62,0,0,1,.46,1.16V22.38H45.38a1.62,1.62,0,0,1,1.16.46,1.69,1.69,0,0,1,0,2.32,1.62,1.62,0,0,1-1.16.46H25.62V45.38a1.62,1.62,0,0,1-.46,1.16,1.69,1.69,0,0,1-2.32,0,1.62,1.62,0,0,1-.46-1.16V25.62H2.62a1.62,1.62,0,0,1-1.16-.46,1.69,1.69,0,0,1,0-2.32,1.62,1.62,0,0,1,1.16-.46Z"
        transform="translate(-1 -1)"
      />
    </svg>
  );
};

export default Plus;
