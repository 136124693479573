const CMSReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CMS_DATA':
      return {
        ...state,
        [action.payload.pageName]: action.payload.data,
      };
    default:
      return state;
  }
};

export default CMSReducer;
