import React from 'react';
import colors from 'Utils/theme';

const Facebook = ({ fill = colors.inHome.blue }) => {
  return (
    <svg className="facebook" viewBox="0 0 23.05 46" fill={fill}>
      <path d="M31.33,8.63c-1.62,0-2.67.37-3.16,1.12a5.13,5.13,0,0,0-.73,2.88v5.2h7.78l-1,8.19H27.44V47H19.3V26H12.47V17.83H19.3v-6q0-5.26,2.8-8A10,10,0,0,1,29.41,1h.2c1.05,0,2.07,0,3.06.08s1.95.12,2.86.22V8.63Z" transform="translate(-12.47 -1)" />
    </svg>
  );
};

export default Facebook;
