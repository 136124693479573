import React from 'react';

import colors from 'Utils/theme';

const ArrowRight = ({ fill = colors.inHome.blue }) => {
  return (
    <svg fill={fill} viewBox="0 0 48 48">
      <path d="M45.23,21,31.29,7a3,3,0,0,0-4.24,4.25L36.79,21H4.51a3,3,0,1,0,0,6H36.79l-9.74,9.73A3,3,0,0,0,31.29,41L45.23,27a4.3,4.3,0,0,0,0-6.08Z" />
    </svg>
  );
};

export default ArrowRight;
