import React from 'react';
import styled from '@emotion/styled';

import { gaLogEvent } from 'Utils/analytics';

import SparkLogo from 'Icons/SparkLogo';

const isProd = process.env.NODE_ENV === 'production' && (!process.env.staging || !process.env.teflon);

const Error = ({ errors }) => {
  !isProd && console.error(errors);

  isProd && gaLogEvent('Error', 'user reached the error page');

  return (
    <StyledErrorWrapper>
      <StyledSpark>
        <SparkLogo />
      </StyledSpark>
      <h2 style={{ textAlign: 'center' }}>Oops, something went wrong. Please try again.</h2>
    </StyledErrorWrapper>
  );
};

export default Error;

const StyledErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  min-height: 100vh;
`;

const StyledSpark = styled.div`
  width: 48px;
  margin-top: -60px;
`;
