const DevicesReducer = (state, action) => {
  switch (action.type) {
    case 'ELIGIBLE_DEVICES':
      return {
        ...state,
        eligibleDevices: action.payload,
      };
    default:
      return state;
  }
};

export default DevicesReducer;
