import React from 'react';
import { StyledFormLabel, StyledFormError, StyledFormRequiredMark } from 'Components/forms/styles';

const Label = ({ label, ...props }) => {
  const showError = props.touched && props.isValid !== null && !props.isValid;

  return showError ? (
    <StyledFormError>{props.errorMessage}</StyledFormError>
  ) : (
    <StyledFormLabel>
      {label}
      {props.required && <StyledFormRequiredMark />}
    </StyledFormLabel>
  );
};

export default Label;
