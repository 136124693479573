import React from 'react';

const useScroll = () => {
  const [scrollX, setScrollX] = React.useState(0);
  const [scrollY, setScrollY] = React.useState(0);

  const onScroll = () => {
    if (typeof window === undefined) {
      return;
    }

    setScrollX(window.pageXOffset);
    setScrollY(window.pageYOffset);
  };

  React.useEffect(() => {
    if (typeof window === undefined) {
      return;
    }

    onScroll();
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return {
    scrollX,
    scrollY,
  };
};

export default useScroll;
