import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';

import { StateContext } from 'Context/StateContext';
import { DispatchContext } from 'context/DispatchContext';

import colors from 'Utils/theme';
import { minTablet } from 'Utils/variables';

import useWindowSize from 'Hooks/useWindowSize';

import CallToAction from 'Components/callToAction';

import Plus from 'Icons/Plus';
import AddressCard from './AddressCard';

import { StyledRadioGroup, StyledRadio } from 'Components/forms/styles';

const StyledCallToAction = styled(CallToAction)`
  margin: 20px auto;

  @media (min-width: ${minTablet}) {
    margin: 30px auto 0 0;
  }
`;

const StyledAddNewButton = styled.button`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid ${colors.inHome.medGrey};
  border-radius: 14px;
  padding: 10px 0;
  margin: 20px 0;
  width: 100%;
  line-height: 1em;
  font-size: 15px;
  cursor: pointer;

  @media (min-width: ${minTablet}) {
    max-width: calc((100% / 3) - 20px);
    min-width: 280px;
    padding: 10px;
    margin: 10px 10px;
    min-height: 233px;
  }

  svg {
    width: 12px;
    height: auto;
    margin-bottom: 10px;
  }
`;

// staging store 5505

const AddressesSelect = ({ isInDrawer = false, inCta = false, openModal, setAddNewAddress, selectFunction, ...props }) => {
  const { dispatch } = useContext(DispatchContext);
  const { addressesState } = useContext(StateContext);

  const { addresses, selectedAddressId } = addressesState;

  const { windowWidth } = useWindowSize();

  const [value, setValue] = useState();

  useEffect(() => {
    selectedAddressId && setValue(selectedAddressId);
  }, [selectedAddressId]);

  const handleChange = (value) => {
    setValue(value);
    if (!inCta) {
      dispatch({ type: 'SET_SELECTED_ADDRESS_ID', payload: value });
      setAddNewAddress(false);
    }
  };

  return (
    <StyledRadioGroup {...props} onChange={(e) => handleChange(e.target.value)} value={value}>
      {addresses
        .sort((a, b) => b.eligible - a.eligible)
        .map((address) => {
          return (
            <StyledRadio key={address.id} value={address.id} inCta={inCta} disabled={address.eligible === false}>
              <AddressCard handleChange={(value) => handleChange(value)} inCta={inCta} openModal={openModal} isInDrawer={isInDrawer} selected={value === address.id} address={address} />
            </StyledRadio>
          );
        })}
      {windowWidth >= 720 && !inCta && (
        <StyledAddNewButton
          isInDrawer={isInDrawer}
          tabIndex="0"
          role="button"
          onClick={() => {
            setAddNewAddress(true);
            sessionStorage.removeItem('address');
            setValue('');
          }}
        >
          <Plus fill={colors.walmart.blue} />
          <span>Add new address</span>
        </StyledAddNewButton>
      )}
      {inCta && (
        <StyledCallToAction
          disabled={!value}
          backgroundColor={colors.walmart.yellow}
          textColor={colors.walmart.blue}
          onClick={() => {
            selectFunction(value);
            setAddNewAddress(false);
          }}
        >
          Continue
        </StyledCallToAction>
      )}
    </StyledRadioGroup>
  );
};

export default AddressesSelect;
