import React from 'react';

import colors from 'Utils/theme';

const Certificate = ({ primary = colors.walmart.yellow, secondary = colors.walmart.blue }) => {
  return (
    <svg viewBox="0 0 48 48">
      <path
        fill={primary}
        d="M3.66,26.73a4.64,4.64,0,0,0,0-5.47A4.62,4.62,0,0,1,3,17.1a4.54,4.54,0,0,1,3-3A4.59,4.59,0,0,0,9.14,9.7,4.57,4.57,0,0,1,11,5.94a4.49,4.49,0,0,1,4.12-.62,4.41,4.41,0,0,0,1.45.24,4.52,4.52,0,0,0,3.7-1.93,4.52,4.52,0,0,1,7.42,0,4.52,4.52,0,0,0,3.7,1.93,4.41,4.41,0,0,0,1.45-.24A4.5,4.5,0,0,1,37,5.94a4.6,4.6,0,0,1,1.87,3.78,4.6,4.6,0,0,0,3.17,4.41,4.59,4.59,0,0,1,3,3,4.65,4.65,0,0,1-.68,4.16,4.64,4.64,0,0,0,0,5.47A4.61,4.61,0,0,1,45,30.88a4.53,4.53,0,0,1-3,3,4.6,4.6,0,0,0-3.19,4.44A4.6,4.6,0,0,1,37,42.06a4.52,4.52,0,0,1-4.15.61,4.52,4.52,0,0,0-5.15,1.7,4.51,4.51,0,0,1-7.41,0,4.52,4.52,0,0,0-5.16-1.69A4.53,4.53,0,0,1,11,42.06,4.63,4.63,0,0,1,9.1,38.27,4.61,4.61,0,0,0,6,33.84a4.55,4.55,0,0,1-3-3A4.63,4.63,0,0,1,3.66,26.73Z"
      />
      <path fill={secondary} d="M21.7,31.58h0A1.69,1.69,0,0,1,20.43,31l-4.8-5.38a1.7,1.7,0,1,1,2.53-2.26l3.57,4,9.2-9.83a1.68,1.68,0,0,1,2.4-.08,1.7,1.7,0,0,1,.08,2.4L22.94,31A1.73,1.73,0,0,1,21.7,31.58Z" />
    </svg>
  );
};

export default Certificate;
