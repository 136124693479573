import React from 'react';
import { Global, css } from '@emotion/core';

const fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Bogle';
        font-weight: 400;
        src: url(${'/static/fonts/BogleWeb-Regular.woff2'}) format('woff2'), url(${'/static/fonts/BogleWeb-Regular.woff'}) format('woff'), url(${'/static/fonts/BogleWeb-Regular.eot'}) format('embedded-opentype'), url(${'/static/fonts/BogleWeb-Regular.ttf'}) format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Bogle';
        font-weight: 400;
        font-style: italic;
        src: url(${'/static/fonts/BogleWeb-RegularItalic.woff2'}) format('woff2'), url(${'/static/fonts/BogleWeb-RegularItalic.woff'}) format('woff'), url(${'/static/fonts/BogleWeb-RegularItalic.eot'}) format('embedded-opentype'), url(${'/static/fonts/BogleWeb-RegularItalic.ttf'}) format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Bogle';
        font-weight: 500;
        src: url(${'/static/fonts/BogleWeb-Medium.woff2'}) format('woff2'), url(${'/static/fonts/BogleWeb-Medium.woff'}) format('woff'), url(${'/static/fonts/BogleWeb-Medium.eot'}) format('embedded-opentype'), url(${'/static/fonts/BogleWeb-Medium.ttf'}) format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Bogle';
        font-weight: 500;
        font-style: italic;
        src: url(${'/static/fonts/BogleWeb-MediumItalic.woff2'}) format('woff2'), url(${'/static/fonts/BogleWeb-MediumItalic.woff'}) format('woff'), url(${'/static/fonts/BogleWeb-MediumItalic.eot'}) format('embedded-opentype'), url(${'/static/fonts/BogleWeb-MediumItalic.ttf'}) format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Bogle';
        font-weight: 700;
        src: url(${'/static/fonts/BogleWeb-Bold.woff2'}) format('woff2'), url(${'/static/fonts/BogleWeb-Bold.woff'}) format('woff'), url(${'/static/fonts/BogleWeb-Bold.eot'}) format('embedded-opentype'), url(${'/static/fonts/BogleWeb-Bold.ttf'}) format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Bogle';
        font-weight: 700;
        font-style: italic;
        src: url(${'/static/fonts/BogleWeb-BoldItalic.woff2'}) format('woff2'), url(${'/static/fonts/BogleWeb-BoldItalic.woff'}) format('woff'), url(${'/static/fonts/BogleWeb-BoldItalic.eot'}) format('embedded-opentype'), url(${'/static/fonts/BogleWeb-BoldItalic.ttf'}) format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'NewYorkIcons';
        font-weight: normal;
        font-style: normal;
        src: url(${'/static/fonts/newyorkicons.eot'});
        font-display: swap;
      }

      @font-face {
        font-family: 'NewYorkIcons';
        font-weight: normal;
        font-style: normal;
        src: url(${'/static/fonts/newyorkicons.woff2'}) format('woff2'), url(${'/static/fonts/newyorkicons.woff'}) format('woff'), url(${'/static/fonts/newyorkicons.ttf'}) format('truetype');
        font-display: swap;
      }
    `}
  />
);

export default fonts;
