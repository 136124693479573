import React from 'react';
import { Global, css } from '@emotion/core';
import { minTablet } from 'Utils/variables';

import colors from 'Utils/theme';

import ResetStyles from './reset';
import FontStyles from './fonts';

export const GlobalStyles = () => (
  <>
    <ResetStyles />
    <FontStyles />

    <Global
      styles={css`
        :root {
          --scale-element: 1;
          --scale-font: 1;

          @media (max-height: 920px) {
            --scale-element: 0.975;
            --scale-font: 0.975;
          }

          @media (max-height: 800px) {
            --scale-element: 0.95;
            --scale-font: 0.95;
          }

          @media (max-height: 690px) {
            --scale-element: 0.925;
            --scale-font: 0.925;
          }
        }

        html,
        body {
          min-height: 100vh;
          margin: 0;

          max-width: 100%;

          font-family: 'Bogle', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
          font-weight: 400;

          color: ${colors.walmart.blue};

          -webkit-overflow-scrolling: touch;

          background-color: ${colors.white};
        }

        body {
          *:active,
          *:focus {
            box-shadow: none;
            outline: 0;
          }
        }

        body:not(.focus-with-mouse) {
          *:focus {
            box-shadow: ${colors.inHome.blue} 0px 0px 0px 1px, ${colors.blue[200]} 0px 0px 0px 4px;
            outline: 0;
          }
        }

        html {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;

          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          box-sizing: border-box;
        }

        main {
          position: relative;
        }

        *,
        *::before,
        *::after {
          box-sizing: inherit;
        }

        input,
        textarea,
        select {
          &::-webkit-input-placeholder {
            color: ${colors.inHome.medGrey};
          }

          &::-moz-placeholder {
            opacity: 1;
            color: ${colors.inHome.medGrey};
          }

          &:-ms-input-placeholder {
            color: ${colors.inHome.medGrey};
          }
        }

        .page-transition-enter {
          opacity: 0;
        }

        .page-transition-enter-active {
          opacity: 1;
          transition: opacity 300ms;
        }

        .page-transition-exit {
          opacity: 1;
        }

        .page-transition-exit-active {
          opacity: 0;
          transition: opacity 300ms;
        }

        strong {
          font-weight: 700;
        }

        body:not(.focus-with-mouse) {
          a,
          button {
            outline: 0;

            &:focus:not(:active) {
              outline: 3px solid #b8dfff;
              outline-offset: 3px;
              box-shadow: 0 0 0 2px #fff, 0 0 0 3px #0170cd;
            }

            &.rounded {
              &:focus:not(:active) {
                outline: 0;
                box-shadow: 0 0 0 2px #fff, 0 0 0 3px #0170cd, 0 0 0 6px #b8dfff;
              }
            }
          }
        }

        svg {
          width: 52px;
          height: auto;
        }

        .wc-chat-container svg {
          width: initial;
          height: auto;
        }

        [id*='frndby_unit']:not(img) {
          min-height: 100vh;
          top: 0 !important;

          iframe {
            min-height: 100vh;
          }
        }

        [id*='frndby_unit_loading'] {
          top: 50% !important;
          margin-top: -40px;
          height: 80px;
          max-height: 80px;
          width: 80px;
          max-width: 80px;
        }

        @media (min-width: ${minTablet}) {
          [id*='frndby_unit']:not(img) {
            top: 50px !important;
            border-radius: 24px;
            height: 600px;
            min-height: initial;

            iframe {
              min-height: initial;
            }
          }

          [id*='frndby_unit_loading'] {
            top: 50% !important;
            margin-top: -40px;
            height: 80px;
            max-height: 80px;
            width: 80px;
            max-width: 80px;
          }

          [id*='frndby_close_btn'] {
            margin-left: -410px !important;
            top: 10px !important;
          }
        }

        .friendbuy-Nsu-bjzV iframe {
          z-index: 1 !important;
        }

        sup {
          position: relative;
          font-size: 0.35em;
          font-weight: normal;
          top: -3px;
        }
      `}
    />
  </>
);
