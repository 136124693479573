import React from 'react';

import colors from 'Utils/theme';

const Pin = ({ fill = colors.inHome.blue }) => {
  return (
    <svg viewBox="0 0 48 48" fill={fill}>
      <path
        d="M12.6,26.9L12.6,26.9L12.6,26.9c-3.8-5.6-2.7-13,2.5-17.4
	c5.1-4.3,12.8-4.3,17.9,0c5.1,4.3,6.2,11.7,2.5,17.3l-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0l-9.9,13.8
	c-0.3,0.4-0.8,0.6-1.3,0.6s-1-0.2-1.3-0.6l-9.9-13.9c0-0.1-0.1-0.1-0.1-0.2C12.7,27,12.6,26.9,12.6,26.9z M29.5,19.9
	c0-3-2.4-5.5-5.5-5.5s-5.5,2.4-5.5,5.5s2.4,5.5,5.5,5.5S29.5,22.9,29.5,19.9z"
      />
    </svg>
  );
};

export default Pin;
