import React from 'react';
import colors from 'Utils/theme';

const LennarLogo = ({ fill = colors.inHome.blue, className, ...props }) => {
  return (
    <svg fill={fill} viewBox="0 0 130.2 13.5" className={className} {...props}>
      <path d="M0,0h5.3c0,3.6,0,7.2,0,10.8c3.1,0,6.3,0,9.4,0c0,0.9,0,1.7,0,2.6H0V0z" />
      <path
        d="M18,0h14.7c0,0.9,0,1.7,0,2.6c-3.1,0-6.3,0-9.5,0c0,0.9,0,1.9,0,2.8c3.1,0,6.3,0,9.5,0c0,0.9,0,1.7,0,2.6
        c-3.1,0-6.3,0-9.5,0c0,0.9,0,1.9,0,2.8c3.1,0,6.3,0,9.5,0c0,0.9,0,1.7,0,2.6H18C18,9,18,4.5,18,0z"
      />
      <path
        d="M36.9,0h6c3.2,3,6.3,5.9,9.5,8.9c0-0.7,0.1-1.4,0-2.1c0-2.2,0-4.5,0-6.7h4.9c0,4.5,0,9,0,13.4H51
        c-3.1-3-6.2-6-9.3-8.9c0,3,0,6,0,8.9h-4.8C36.9,9,36.9,4.5,36.9,0z"
      />
      <path
        d="M61.4,0h6c3.1,3,6.3,5.9,9.5,8.9c0.1-1.3,0-2.6,0-3.9c0-1.6,0-3.3,0-5h4.9c0,4.5,0,9,0,13.4h-6.2
        c-3.1-3-6.2-6-9.3-8.9c0,3,0,6,0,8.9h-4.8C61.4,9,61.4,4.5,61.4,0z"
      />
      <path
        d="M92.3,0h5.7c2.5,4.5,4.9,9,7.4,13.4h-5.4c-0.5-0.9-0.9-1.8-1.4-2.7c-2,0-4,0-6,0c0.5-0.9,0.9-1.7,1.4-2.6
        c1.1,0,2.1,0,3.2,0c-0.7-1.4-1.4-2.7-2.1-4.1c-1.6,3.1-3.2,6.3-4.9,9.4h-5.4C87.4,9,89.8,4.5,92.3,0z"
      />
      <path
        d="M108.5,0h11.2c1.2,0,2.4,0.1,3.5,0.4c0.8,0.2,1.5,0.5,2.1,0.9c0.4,0.3,0.8,0.6,1,1c0.3,0.5,0.4,1.2,0.3,1.8
        c-0.1,0.8-0.5,1.5-1,2.1c-0.9,0.7-1.9,1.2-3,1.5c1.8,1.9,3.6,3.8,5.4,5.7h-6.5c-2.3-2.5-4.6-4.9-6.9-7.3c1.3,0,2.7,0,4-0.1
        c0.6,0,1.1-0.2,1.6-0.5c0.4-0.2,0.7-0.5,0.8-0.9c0.1-0.4,0.1-0.9-0.1-1.3c-0.2-0.3-0.6-0.5-0.9-0.6c-0.6-0.2-1.3-0.2-2-0.2
        c-1.4,0-2.9,0-4.3,0c0,3.6,0,7.3,0,11h-5.3C108.5,9,108.5,4.5,108.5,0z"
      />
      <path
        d="M128.6,0h0.4c0.6,0.1,1.2,0.7,1.2,1.3c0.1,0.6-0.4,1.2-1,1.4c-0.4,0.1-0.7,0.1-1.1-0.1c-0.4-0.2-0.7-0.6-0.7-1
        c0-0.3,0-0.6,0.1-0.9C127.7,0.4,128.1,0,128.6,0z M128.4,0.3c-0.5,0.1-0.8,0.6-0.8,1.1c0,0.5,0.3,0.9,0.8,1.1
        c0.4,0.1,0.9,0,1.2-0.2c0.3-0.3,0.5-0.8,0.3-1.2C129.7,0.5,129,0.1,128.4,0.3z"
      />
      <path
        d="M128.2,2.2c0-0.5,0-1,0-1.6c0.3,0,0.7,0,1,0c0.2,0.1,0.4,0.4,0.2,0.6l-0.2,0.2c0.4,0.1,0.3,0.5,0.3,0.7
        c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.4-0.1c0,0.2,0,0.4,0,0.5C128.4,2.2,128.3,2.2,128.2,2.2z M128.6,0.9
        c0,0.1,0,0.3,0,0.4l0.4-0.1l0-0.3C128.9,0.9,128.7,0.9,128.6,0.9z"
      />
    </svg>
  );
};

export default LennarLogo;
