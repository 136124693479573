import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import colors from 'Utils/theme';

import Modal from '../modal';

const ErrorBanner = ({ errors = null, message = null, closeFunction = () => {}, link = null, useModal = false }) => {
  message = errors[0]?.message;

  errors && console.error(errors);
  const [showError, setShowError] = useState(true);

  useEffect(() => {
    const handleErrorTimeout = setTimeout(() => {
      if (showError) {
        setShowError(false);
        closeFunction();
      }
    }, 4000);

    return () => {
      clearTimeout(handleErrorTimeout);
    };
  });

  if (!errors) return null;

  if (useModal) {
    return (
      <Modal
        displayTriggerComponent={false}
        openModalCall={showError}
        content={() => (
          <StyledMessage>
            {message ? message : 'Oops, something went wrong. Please try again.'}
            {link && link}
          </StyledMessage>
        )}
        onClose={() => closeFunction()}
      />
    );
  }

  return (
    <StyledErrorBannerContainer className={showError ? 'show' : 'hide'}>
      <StyledErrorMessage>
        <Message>{message ? message : 'Oops, something went wrong. Please try again.'}</Message>
        <StyledClose onClick={() => setShowError(false)} />
      </StyledErrorMessage>
    </StyledErrorBannerContainer>
  );
};

export default ErrorBanner;

const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 40px;
  font-size: 18px;
  margin-top: 30px;
  color: ${colors.inHome.error};
`;

const StyledErrorBannerContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  bottom: -100px;
  left: 0;
  transition: bottom 300ms;
  z-index: 6666;

  &.show {
    bottom: 0;
  }
`;

const StyledErrorMessage = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.red[100]};
  border: 1px solid ${colors.red[200]};
  border-radius: 24px 24px 0 0;
  color: ${colors.red[400]};
  width: 95%;
  padding: 0 20px;
  height: 100px;
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-content: center;
  justify-content: center;
`;

const Message = styled.p`
  padding: 0;
  margin: 0;
`;

const StyledClose = styled.button`
  background-color: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: ${colors.red[400]};
  border: none;
  transform: rotate(45deg);

  &:before {
    content: '+';
    font-size: 30px;
    font-family: 'BogleWeb';
    font-weight: normal;
    line-height: 14px;
  }
`;
