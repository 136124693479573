import moment from 'moment';
//const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// eslint-disable-next-line
const YUP_EMAIL_REGEXP = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
const NO_WHITESPACE_REGEXP = /\S+.*/;
const PHONE_REGEXP = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export default {
  isRequired: (config = { message: 'Field is required' }) => (value) => {
    return value === '' ? config.message : null;
  },

  isMinLength: (config = { message: 'Minimum length is 3 characters', length: 3 }) => (value) => {
    if (value === '') return null;
    return value.length < config.length ? config.message : null;
  },

  isMaxLength: (config = { message: 'Maximum length is 3 characters', length: 3 }) => (value) => {
    if (value === '') return null;
    return value.length > config.length ? config.message : null;
  },

  isLength: (config = { message: 'Must only be 2 characters', length: 2 }) => (value) => {
    if (value === '') return null;
    return value.length !== config.length ? config.message : null;
  },

  isEmail: (config = { message: 'Must use valid email address' }) => (value) => {
    if (value === '') return null;
    return !YUP_EMAIL_REGEXP.test(value) ? config.message : null;
  },

  isPhone: (config = { message: 'Must use valid phone number' }) => (value) => {
    if (value === '') return null;
    return !PHONE_REGEXP.test(value) ? config.message : null;
  },

  isNumber: (config) => (value) => {
    if (value === '') {
      return null;
    }
    return isNaN(value) ? config.message : null;
  },

  isNaN: (config) => (value) => {
    if (value === '') {
      return null;
    }
    return isNaN(value) ? null : config.message;
  },

  isEmpty: (value) => {
    return !NO_WHITESPACE_REGEXP.test(value);
  },

  isAcceptedCC: (config = { message: 'Please use an accepted credit card' }) => (value) => {
    return value ? null : config.message;
  },

  isValidExpirationDate: (config = { message: 'Enter valid year', expiryMonth: 0 }) => (value) => {
    return +config.expiryMonth < +moment().format('MM') && +value <= +moment().format('YYYY') ? config.message : null;
  },
};
