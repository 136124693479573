const FeatureFlagReducer = (state, action) => {
    switch (action.type) {
      case 'SET_FEATURE_FLAGS':
        return {
          ...state,
          featureFlags: action.payload,
        };
      case 'SET_HAS_LOADED':
        return {
          ...state,
          hasLoaded: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default FeatureFlagReducer;