import React from 'react';
import styled from '@emotion/styled';

import colors from 'Utils/theme';
import { max1025, minTablet } from 'Utils/variables';

import Logo from 'Icons/Logo';
import LennarLogo from 'Icons/LennarLogo';
import Close from 'Icons/Close';

const TwoLogoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.isFixed ? 'row' : 'column')};
  align-items: center;
  justify-content: ${(props) => (props.isFixed ? 'flex-start' : 'center')};

  svg {
    position: relative;
    width: auto;

    &:nth-of-type(1) {
      width: auto;
      height: ${(props) => (props.isFixed ? '28px' : '36px')};
    }

    &:nth-of-type(2) {
      margin: ${(props) => (props.isFixed ? '0 15px' : '15px 0 20px')};
      max-width: ${(props) => (props.isFixed ? '12px' : '14px')};
      height: auto;
    }

    &:nth-of-type(3) {
      width: auto;
      height: ${(props) => (props.isFixed ? '14px' : '20px')};
    }
  }

  @media (min-width: ${minTablet}) {
    flex-direction: row;
    justify-content: space-between;

    svg:nth-of-type(1) {
      height: ${(props) => (props.isFixed ? '28px' : '40px')};
    }

    svg:nth-of-type(2) {
      margin: ${(props) => (props.isFixed ? '0 15px' : '0 20px')};
    }
  }

  @media (min-width: ${max1025}) {
    svg:nth-of-type(1) {
      height: ${(props) => (props.isFixed ? '28px' : '72px')};
    }

    svg:nth-of-type(2) {
      margin: ${(props) => (props.isFixed ? '0 15px' : '0 30px')};
      max-width: ${(props) => (props.isFixed ? '12px' : '24px')};
    }

    svg:nth-of-type(3) {
      height: ${(props) => (props.isFixed ? '14px' : '36px')};
    }
  }
`;

const InHomeLennarLogo = ({ isFixed = false }) => {
  return (
    <TwoLogoContainer isFixed={isFixed}>
      <Logo secondary={isFixed ? colors.walmart.blue : colors.white} />
      <Close fill={colors.inHome.grey} />
      <LennarLogo fill={isFixed ? colors.inHome.lennarBlue : colors.white} />
    </TwoLogoContainer>
  );
};

export default InHomeLennarLogo;
