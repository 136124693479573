import React from 'react';
import { isEmpty, truncate } from 'lodash';
import Head from 'next/head';

const shorten = (d) => truncate(d.replace(/\n+/g, ' '), { length: 300, separator: '. ' });

export const defaultMeta = {
  title: 'Walmart InHome | In Home Grocery Delivery',
  description: "Walmart InHome saves you time & money by bringing a wide selection of fresh groceries straight to your fridge, even when you're not home",
  image: '/static/walmart-inhome-share-image-1.jpg',
  imageAlt: 'Walmart Associate opening fridge',
};

const Meta = ({ title, description, image, imageAlt, children }) => {
  const metaTitle = !isEmpty(title) ? `${title} | Walmart InHome` : defaultMeta.title;
  const metaDescription = !isEmpty(description) ? description : shorten(defaultMeta.description);
  const metaImage = !isEmpty(image) ? image : defaultMeta.image;
  const metaImageAlt = !isEmpty(image) ? imageAlt : defaultMeta.imageAlt;

  return (
    <Head>
      <title key="title">{metaTitle}</title>
      <meta key="description" name="description" content={metaDescription} />

      <meta key="twitter-title" name="twitter:title" content={metaTitle} />
      <meta key="twitter-description" name="twitter:description" content={metaDescription} />
      <meta key="twitter-image" name="twitter:image" content={`${process.env.NEXT_PUBLIC_SITE_URL}${metaImage}`} />
      <meta key="twitter-image-alt" name="twitter:image:alt" content={metaImageAlt} />

      <meta key="og-title" property="og:title" content={metaTitle} />
      <meta key="og-description" property="og:description" content={metaDescription} />
      <meta key="og-image" property="og:image" content={`${process.env.NEXT_PUBLIC_SITE_URL}${metaImage}`} />
      <meta key="og-image-alt" property="og:image:alt" content={metaImageAlt} />
      {children}
    </Head>
  );
};

export default Meta;
