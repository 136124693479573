import React from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';
import colors from 'Utils/theme';

import { max1025 } from 'Utils/variables';

import { responsiveFont } from 'Styles/helpers';

const Placeholder = styled.span`
  color: ${(props) => (props.active ? props.activeColor : colors.inHome.darkGrey)};
  position: absolute;
  pointer-events: none;
  font-size: ${responsiveFont({ sizes: '14,15' })};
  top: ${(props) => (props.withFocus ? '-12px' : '6px')};
  left: ${(props) => (props.withFocus ? '8px' : props.wide ? '20px' : '10px')};
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: all 0.2s;
  z-index: 669;

  @media (min-width: ${max1025}) {
    top: ${(props) => (props.withFocus ? '-15px' : '10px')};
    left: ${(props) => (props.centerOnDesktop ? 0 : props.withFocus ? '8px' : props.wide ? '40px' : '20px')};

    ${(props) =>
      props.centerOnDesktop &&
      css`
        right: 0;
      `}
  }
`;

const MovingPlaceholder = ({ placeholder, inputRef, controlledValue = '', wide = false, activeColor = colors.inHome.blue, withFocus = false, centerOnDesktop = false }) => {
  return (
    <Placeholder activeColor={activeColor} withFocus={withFocus} active={inputRef && inputRef.current && inputRef.current.value.length > 0 && controlledValue?.length > 0} wide={wide} centerOnDesktop={centerOnDesktop}>
      {placeholder}
    </Placeholder>
  );
};

export default MovingPlaceholder;
