import React from 'react';
import { StyledSelect } from 'Components/forms/styles';

const StateSelect = (props) => {
  return (
    <StyledSelect {...props} className={'include-arrow'}>
      <option value="">​</option>
      <option value="AL">A​L</option>
      <option value="AK">A​K</option>
      <option value="AZ">A​Z</option>
      <option value="AR">A​R</option>
      <option value="CA">C​A</option>
      <option value="CO">C​O</option>
      <option value="CT">C​T</option>
      <option value="DC">D​C</option>
      <option value="DE">D​E</option>
      <option value="FL">F​L</option>
      <option value="GA">G​A</option>
      <option value="HI">H​I</option>
      <option value="ID">I​D</option>
      <option value="IL">I​L</option>
      <option value="IN">I​N</option>
      <option value="IA">I​A</option>
      <option value="KS">K​S</option>
      <option value="KY">K​Y</option>
      <option value="LA">L​A</option>
      <option value="ME">M​E</option>
      <option value="MD">M​D</option>
      <option value="MA">M​A</option>
      <option value="MI">M​I</option>
      <option value="MN">M​N</option>
      <option value="MS">M​S</option>
      <option value="MO">M​O</option>
      <option value="MT">M​T</option>
      <option value="NE">N​E</option>
      <option value="NV">N​V</option>
      <option value="NH">N​H</option>
      <option value="NJ">N​J</option>
      <option value="NM">N​M</option>
      <option value="NY">N​Y</option>
      <option value="NC">N​C</option>
      <option value="ND">N​D</option>
      <option value="OH">O​H</option>
      <option value="OK">O​K</option>
      <option value="OR">O​R</option>
      <option value="PA">P​A</option>
      <option value="RI">R​I</option>
      <option value="SC">S​C</option>
      <option value="SD">S​D</option>
      <option value="TN">T​N</option>
      <option value="TX">T​X</option>
      <option value="UT">U​T</option>
      <option value="VT">V​T</option>
      <option value="VA">V​A</option>
      <option value="WA">W​A</option>
      <option value="WV">W​V</option>
      <option value="WI">W​I</option>
      <option value="WY">W​Y</option>
      <option value="AA">A​A</option>
      <option value="AP">A​P</option>
      <option value="AE">A​E</option>
      <option value="AS">A​S</option>
      <option value="GU">G​U</option>
      <option value="MP">M​P</option>
      <option value="PW">P​W</option>
      <option value="PR">P​R</option>
      <option value="VI">V​I</option>
    </StyledSelect>
  );
};

export default StateSelect;
