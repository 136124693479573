import React from 'react';

import colors from 'Utils/theme';

const Homebase = ({ primary = colors.inHome.homebaseTeal, secondary = colors.inHome.homebaseBlue }) => {
  return (
    <svg viewBox="0 0 48 48">
      <linearGradient id="gradientMask" gradientUnits="userSpaceOnUse" x1="24" y1="42.1113" x2="24" y2="5.1357">
        <stop offset="0" style={{ stopColor: primary }} />
        <stop offset="1" style={{ stopColor: secondary }} />
      </linearGradient>
      <path
        fill="url(#gradientMask)"
        d="M24,6.6c-0.5,0-0.9,0.1-1.2,0.3L9.9,14.3c-0.6,0.4-1.2,1.4-1.2,2.2v15c0,0.7,0.6,1.8,1.2,2.2
      l12.8,7.5c0.3,0.2,0.8,0.3,1.2,0.3c0.5,0,0.9-0.1,1.2-0.3L38,33.7c0.6-0.4,1.2-1.4,1.2-2.2v-15c0-0.7-0.6-1.8-1.2-2.2L25.2,6.8
      C24.9,6.7,24.5,6.6,24,6.6z M29.6,27c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1l4.9,2.9c0.3,0.1,0.4,0.4,0.5,0.7
      c0.1,0.3,0,0.6-0.1,0.9c-0.3,0.5-1,0.7-1.5,0.4l-4.4-2.6l-4.4,2.6c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.8-0.2-1-0.6
      c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.3-0.5,0.5-0.7L29.6,27 M29.6,17.9c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l4,2.4
      c0.3,0.2,0.6,0.6,0.6,1v5.7c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-1.8-1c-0.1,0-0.1-0.1-0.1-0.2v-4l-2.6-1.5
      c-0.1,0-0.1-0.1-0.1-0.2V18C29.4,18,29.5,17.9,29.6,17.9 M26.1,12.4c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1
      c0.3,0.2,0.6,0.6,0.6,1V26c0,0.1,0,0.2-0.1,0.2l-1.8,1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.2V15.3l-4.8,2.8v12.7
      c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1V17.5c0-0.4,0.2-0.8,0.6-1L26.1,12.4 M16.8,21.8c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
      c0.1,0,0.1,0.1,0.1,0.2V24c0,0.1,0,0.2-0.1,0.2L14,25.9v4.9c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1v-5.5c0-0.4,0.2-0.8,0.6-1
      L16.8,21.8 M22.1,26.8c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1C22.6,27.9,22.1,27.4,22.1,26.8
       M22.1,23.3c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1C22.6,24.4,22.1,23.9,22.1,23.3 M24,4.3
      c0.9,0,1.7,0.2,2.3,0.6l12.8,7.5c1.3,0.8,2.3,2.6,2.3,4.1v15c0,1.5-1,3.3-2.3,4.1l-12.8,7.5c-0.6,0.3-1.3,0.5-2,0.6h-0.6
      c-0.8,0-1.5-0.2-2-0.6L8.8,35.6c-1.2-0.7-2.2-2.4-2.3-3.9V16.3c0.1-1.5,1.1-3.1,2.3-3.9l12.8-7.5C22.3,4.5,23.1,4.3,24,4.3z
       M23.2,18.7c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1C22.1,19.2,22.6,18.7,23.2,18.7z"
      />
    </svg>
  );
};

export default Homebase;
