import React from 'react';
import App from 'next/app';
import { PageTransition } from 'next-page-transitions';
import moment from 'moment';

import { getCookie, getSlugs, setWPlusQueryCookie } from 'Utils';
import { pixelInit, pageView, jungroupEvent } from 'Utils/analytics';

import Devtools from 'Components/devtools';
import HeadPreload from 'Components/headPreload';
import Meta from 'Components/meta';

import Styles from './Styles';
import Providers from './Providers';

import '@walmart-web/livingdesign-components/index.esm.css';

const versionId = process.env.version.split('.').join('');

pixelInit(); // initialize analytics
class InHomeApp extends App {
  constructor(props) {
    super(props);

    this.state = { samePage: false, init: true, meta: null };
  }

  clearExpiredSession = (route) => {
    const timeStamp = moment().format();
    const sessionTimestamp = sessionStorage.getItem('session');
    const expired = moment(sessionTimestamp).isBefore(moment(timeStamp).subtract(12, 'h'));

    if (expired) {
      return window.location.assign(route);
    }
  };

  getMetaData = (url) => {
    const shareImage2Meta = {
      image: '/static/walmart-inhome-share-image-2.jpg',
      imageAlt: 'Walmart Associate stocking fridge',
    };

    const shareImage3Meta = {
      image: '/static/walmart-inhome-share-image-3.jpg',
      imageAlt: 'Walmart Associate closing the front door',
    };

    const shareImage4Meta = {
      image: '/static/walmart-inhome-share-image-4.jpg',
      imageAlt: 'Walmart Associate closing the front door',
    };

    const groceryMeta = {
      title: 'Get groceries delivered all the way into your fridge',
      description: 'Save time, save money, and come home to a fully stocked fridge.',
      ...shareImage2Meta,
    };

    const pharmacyMeta = {
      title: 'Prescription delivery to your home',
      description: 'Walmart InHome can deliver your prescriptions safely & discreetly right to your kitchen counter.',
      ...shareImage2Meta,
    };

    const returnsMeta = {
      title: 'Return pickup from your home',
      description: 'Walmart InHome now picks up returns from your home—no labels, shipping boxes, or extra trip required.',
      ...shareImage2Meta,
    };

    const lennarMeta = {
      title: 'Unlimited Walmart InHome delivery for Lennar homeowners',
      description: 'Get deliveries placed safely inside your Lennar Connected Home, even while you’re out. Sign up for tip- & fee-free delivery with Walmart InHome.',
      ...shareImage2Meta,
    };

    const genieMeta = {
      title: 'Get in-garage Walmart InHome delivery with your Genie',
      description: "Have us deliver groceries & more into your garage, even while you're out. Try 30 free days of fee & tip-free delivery with Walmart InHome.",
      ...shareImage2Meta,
    };

    switch (getSlugs(url)[0]) {
      case 'landing-grocery':
        return this.setState({
          meta: { ...groceryMeta },
        });
      case 'landing-pharmacy':
        return this.setState({
          meta: { ...pharmacyMeta },
        });
      case 'landing-returns':
        return this.setState({
          meta: { ...returnsMeta },
        });
      case 'landing-lennar':
        return this.setState({
          meta: { ...lennarMeta },
        });
      case 'landing-genie':
        return this.setState({
          meta: { ...genieMeta },
        });
      case 'landing-grocery-delivery':
        return this.setState({
          meta: {
            title: 'A smart lock opens your door to a new kind of delivery',
            description: 'Clear your to-do list of grocery shopping and prescription pick-ups. We get them delivered safely into your home, wherever you are in your day.',
          },
        });
      case 'how-it-works':
        return this.setState({
          meta: {
            title: 'Getting set up with InHome',
            description: 'We take your to-do list and get it done, with easy setup and secure, transparent deliveries.',
            ...shareImage4Meta,
          },
        });
      case 'our-associates':
        return this.setState({
          meta: {
            title: 'Meet Our Delivery Associates',
            description: 'Walmart InHome uses experienced, insured, and background checked associates to safely and securely deliver your groceries to your kitchen or garage fridge',
            ...shareImage2Meta,
          },
        });
      case 'fyi':
        return this.setState({
          meta: {
            title: 'InHome Frequently Asked Questions',
            description: 'Learn more about the new Walmart InHome delivery service by visiting our frequently asked questions page',
            ...shareImage3Meta,
          },
        });
      case 'get-started':
        return this.setState({
          meta: {
            title: 'Sign Up For In Home Grocery Delivery',
            description: 'Get started with a Walmart InHome membership today and get a 80% discounted smart lock today! A $229 value at $49.95!',
          },
        });
      case 'refer':
        return this.setState({
          meta: {
            title: 'Tell A Friend, Get $20',
            description: 'As a special offer, tell a Friend About Walmart InHome and get $20 off your next order',
            ...shareImage2Meta,
          },
        });
      case 'referral-terms':
        return this.setState({
          meta: {
            title: 'Tell A Friend, Get $20',
            description: 'As a special offer, tell a Friend About Walmart InHome and get $20 off your next order',
            ...shareImage2Meta,
          },
        });
      case 'zip-code':
        return this.setState({
          meta: {
            title: 'InHome is here. Start your 30-day free trial now',
            description: 'Enter your home address to see if your neighborhood is in our delivery area',
          },
        });
      default:
        return this.setState({
          meta: null,
        });
    }
  };

  hasSod = () => {
    const hasDocument = typeof document !== 'undefined';

    if (hasDocument) {
      return +getCookie('sod') === 1;
    }
  };

  hasGPCHeader = () => {
    if (typeof window === 'undefined') {
      return;
    }

    const gpcPreference = navigator.globalPrivacyControl;

    return gpcPreference === '1' || gpcPreference === 1 || gpcPreference === true; // browsers implement differently
  };

  componentDidMount() {
    this.clearExpiredSession(`${window.location.pathname}?v${versionId}`);

    const path = window.location.pathname;

    this.getMetaData(path);

    if (path.includes('/orders')) {
      window.location = '/apps';
    }

    setWPlusQueryCookie();

    const { router } = this.props;

    if (router && router.events && typeof router.events.on === 'function') {
      router.events.on('routeChangeStart', (url) => {
        const { samePage } = this.state;
        const path = url.includes('?') ? url.slice(0, url.indexOf('?')) : url;

        const pixel = document.getElementById('Pandora');

        if (pixel) {
          pixel.remove();
        }

        this.getMetaData(path);

        if (path !== router.pathname && samePage) {
          this.setState({ samePage: false });
        }

        if (path === router.pathname && !samePage) {
          this.setState({ samePage: true });
        }
      });

      router.events.on('routeChangeComplete', (url) => {
        typeof window !== 'undefined' && window.scroll(0, 0);

        const { samePage } = this.state;

        this.clearExpiredSession(url ? `${url}?v${versionId}` : `/?v${versionId}`);

        if (!samePage) {
          if (url && url !== '/') {
            getSlugs(url)[0].includes('get-started') && jungroupEvent('landing_page');
          }

          pageView(url);
        }
      });
    }
  }

  render() {
    const {
      Component,
      pageProps,
      router: { route },
    } = this.props;

    const { meta } = this.state;

    return (
      <>
        <Styles />
        <HeadPreload />
        <Meta {...meta}>
          {!this.hasSod() && !this.hasGPCHeader() && (
            <>
              <script
                dangerouslySetInnerHTML={{
                  __html: `(function(w, d, t, r, u) {
              w[u] = w[u] || [];
              w[u].push({ projectId: '10000', properties: { pixelId: '10114913' } });
              var s = d.createElement(t);
              s.src = r;
              s.async = true;
              s.onload = s.onreadystatechange = function() {
                var y,
                  rs = this.readyState,
                  c = w[u];
                if (rs && rs != 'complete' && rs != 'loaded') {
                  return;
                }
                try {
                  y = YAHOO.ywa.I13N.fireBeacon;
                  w[u] = [];
                  w[u].push = function(p) {
                    y([p]);
                  };
                  y(c);
                } catch (e) {}
              };
              var scr = d.getElementsByTagName(t)[0],
                par = scr.parentNode;
              par.insertBefore(s, scr);
              })(window, document, 'script', 'https://s.yimg.com/wi/ytc.js', 'dotq');`,
                }}
              />
            </>
          )}
        </Meta>

        <Providers>
          <PageTransition classNames="page-transition" timeout={300}>
            <Component {...pageProps} key={route} />
          </PageTransition>
        </Providers>
        {process.env.NODE_ENV === 'development' && <Devtools />}
      </>
    );
  }
}

export default InHomeApp;
