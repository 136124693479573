import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring';

import Logo from 'Icons/Logo';
import CloseIcon from 'Icons/Close';

import * as v from 'Utils/variables';
import { trapFocus } from 'Utils/trapFocus';

import colors from 'Utils/theme';

import useMedia from 'Hooks/useMedia';
import useKeyDown from 'Hooks/useKeydown';

import Container from '../container';
import Row from '../row';
import Column from '../column';
import Link from '../link';

const Modal = styled(animated.div)`
  display: none;
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 100;
  border-radius: 0;

  transform: translateY(-100px);
  will-change: transform;

  overflow: hidden;

  @media (max-width: ${v.max720}) {
    background-color: rgba(255, 255, 255, 1);
  }

  @media (min-width: ${v.minTablet}) {
    width: 100%;
    height: auto;
  }

  @media (min-width: ${v.min1025}) {
    display: block;
  }
`;

const Content = styled.div`
  position: relative;

  padding: ${(props) => (props.hasInHome ? '70px 0 44px' : '100px 0 44px')};

  width: 100%;

  @media (min-width: ${v.minTablet}) {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  margin-bottom: 20px;
`;

const HeaderLink = styled(Link)`
  display: block;
  flex-shrink: 0;

  svg {
    width: 113px;
    height: 28px;

    @media (min-width: ${v.minTablet}) {
      width: 149px;
      height: 37px;
    }
  }
`;

const SubOverlay = styled(animated.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  opacity: 0;

  will-change: opacity;
`;

const ModalContainer = styled.div`
  position: relative;
  z-index: 3;
`;

const CloseButton = styled.button`
  display: block;

  position: relative;
  z-index: 10;
  width: 24px;
  height: 24px;

  margin: -10px;

  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const SubMenu = ({ children, isVisible, toggle, hasInHome }) => {
  const isMobile = useMedia('(max-width: 839px)');
  const dialog = useRef(null);
  const tabIndex = isVisible ? 0 : -1;

  const [subOverlayProps, setSubOverlay] = useSpring(() => ({
    opacity: 0,
    pointerEvents: 'none',
    config: { mass: 0.6, friction: 16 },
  }));

  const [listProps, setList] = useSpring(() => ({
    opacity: 0,
    y: isMobile ? 0 : -100,
    pointerEvents: isMobile ? 'none' : 'all',
    config: { mass: 0.3, friction: 16 },
  }));

  useKeyDown(handleKeyPress);

  function handleKeyPress(e, keys) {
    if (!isVisible) {
      return;
    }

    trapFocus(e, keys, dialog);
  }

  useEffect(() => {
    if (isVisible && dialog.current) {
      dialog.current.querySelector('button').focus();
    }

    setSubOverlay({
      opacity: isVisible ? 1 : 0,
      pointerEvents: isVisible ? 'all' : 'none',
    });

    setList({
      opacity: isVisible ? 1 : 0,
      y: isVisible ? 0 : -100,
      pointerEvents: isVisible ? 'all' : 'none',
    });
  }, [isVisible]);
  useEffect(() => {
    if (isMobile) {
      setList({
        opacity: 0,
        y: -100,
        pointerEvents: 'none',
      });
    }
  }, [isMobile]);

  return (
    <>
      <Modal
        ref={dialog}
        aria-modal={isVisible ? 'true' : 'false'}
        role="dialog"
        style={{
          ...listProps,
          transform: listProps.y.interpolate((y) => `translate3d(0, ${y}%, 0)`),
        }}
      >
        <ModalContainer>
          <Content hasInHome={hasInHome}>
            <Row>
              <Column all={{ width: 20 }} md={{ width: 18, offsetLeft: 2 }}>
                <Header>
                  <CloseButton aria-label="Close Menu" tabIndex={tabIndex} onClick={toggle}>
                    <CloseIcon />
                  </CloseButton>
                </Header>

                {children}
              </Column>
            </Row>
          </Content>
        </ModalContainer>
        <SubOverlay onClick={toggle} style={{ ...subOverlayProps }} tabIndex="-1" />
      </Modal>
    </>
  );
};

export default SubMenu;
