import styled from '@emotion/styled';
import { math } from 'polished';

import * as v from 'Utils/variables';

export default styled.div`
  flex-grow: 1;

  margin: 0 auto;
  padding-left: ${v.containerGutterMobile};
  padding-right: ${v.containerGutterMobile};

  max-width: ${math(`${v.pageWidth} + ${v.containerGutterMobile}`)};

  @media (min-width: ${v.minMobile}) {
    max-width: ${math(`${v.pageWidth} + ${v.containerGutterMobile} * 2`)};
  }

  @media (min-width: ${v.minTablet}) {
    padding-left: ${v.containerGutterTablet};
    padding-right: ${v.containerGutterTablet};

    max-width: ${math(`${v.pageWidth} + ${v.containerGutterTablet} * 2`)};
  }

  @media (min-width: ${v.minDesktop}) {
    padding-left: ${v.containerGutterDesktop};
    padding-right: ${v.containerGutterDesktop};

    max-width: ${math(`${v.pageWidth} + ${v.containerGutterDesktop} * 2`)};
  }
`;
