const AddressesReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ADDRESSES':
      return {
        ...state,
        addresses: action.payload,
      };
    case 'SET_SELECTED_ADDRESS_ID':
      return {
        ...state,
        selectedAddressId: action.payload,
      };
    case 'SET_STORED_ADDRESS':
      return {
        ...state,
        storedAddress: action.payload,
      };
    default:
      return state;
  }
};

export default AddressesReducer;
