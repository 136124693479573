import React from 'react';
import colors from 'Utils/theme';

const Logo = ({ primary = colors.walmart.yellow, secondary = colors.walmart.blue, className }) => {
  return (
    <svg viewBox="0 0 1043.88 258.4" className={className}>
      <path
        fill={primary}
        d="M114.9,87c6.1,0,11-3.1,11.7-7.2l6-67.6c0-6.7-7.8-12.2-17.7-12.2S97.3,5.5,97.3,12.2l6,67.6c.7,4.1,5.7,7.2,11.6,7.2h0M78.4,108.1c3-5.2,2.8-11.1-.4-13.7L22.5,55.5c-5.8-3.3-14.4.7-19.3,9.2s-4,18,1.7,21.4l61.5,28.6c3.7,1.4,9-1.4,12-6.6h0m73,0c3,5.2,8.2,8,12.1,6.5L225,86c5.8-3.3,6.6-12.8,1.7-21.4s-13.6-12.5-19.4-9.2L151.8,94.3c-3.1,2.7-3.4,8.6-.4,13.8h0m-36.5,63.3c6.1,0,11,3.1,11.7,7.2l6,67.6c0,6.7-7.8,12.2-17.7,12.2s-17.6-5.5-17.6-12.2l6-67.5c.7-4.2,5.7-7.3,11.6-7.3h0m36.5-21.1c3-5.2,8.2-8,12.1-6.5L225,172.4c5.8,3.4,6.6,12.9,1.7,21.4s-13.6,12.5-19.4,9.2l-55.5-38.9c-3.1-2.7-3.4-8.6-.4-13.8h0m-73,0c3,5.2,2.8,11.1-.4,13.8L22.5,203c-5.8,3.3-14.4-.7-19.3-9.2s-4-18,1.7-21.4l61.5-28.6c3.7-1.5,9,1.3,12,6.5h0"
      />
      <path
        fill={secondary}
        d="M292.1,48.6a4.79,4.79,0,0,1,4.6-4.6h9.6a4.79,4.79,0,0,1,4.6,4.6V207.8a4.79,4.79,0,0,1-4.6,4.6h-9.6a4.79,4.79,0,0,1-4.6-4.6ZM345,104.7a3.94,3.94,0,0,1,3.6-4.3h.7c7,0,10.8,5.3,11.5,12.5A57.42,57.42,0,0,1,398.1,99c32.2,0,40.2,20.9,40.2,49.5v58.7a5.47,5.47,0,0,1-5.3,5.3h-7.2a5.34,5.34,0,0,1-5.3-5.3V146.1c0-21.2-7.2-31-23.6-31-20.9,0-34.1,15.4-34.1,15.4v76.7a5.47,5.47,0,0,1-5.3,5.3h-7.2a5.34,5.34,0,0,1-5.3-5.3V104.7ZM475.6,48.6a4.79,4.79,0,0,1,4.6-4.6h9.4a4.59,4.59,0,0,1,4.6,4.6h0v70.2h97.6V48.6a4.59,4.59,0,0,1,4.6-4.6h9.4a4.79,4.79,0,0,1,4.6,4.6V207.8a4.79,4.79,0,0,1-4.6,4.6h-9.4a4.59,4.59,0,0,1-4.6-4.6h0V134.9H494.2v72.9a4.59,4.59,0,0,1-4.6,4.6h-9.4a4.79,4.79,0,0,1-4.6-4.6V48.6ZM685.8,98.9c30.8,0,55.8,26.4,55.8,57.7,0,31.7-25,58.4-55.8,58.4S630,188.3,630,156.6C630,125.3,655,98.9,685.8,98.9Zm0,100c21.9,0,38-19.2,38-42.3,0-22.6-16.1-41.6-38-41.6s-38.2,19-38.2,41.6c0,23.1,16.3,42.3,38.2,42.3Zm73.6-94.2a3.94,3.94,0,0,1,3.6-4.3h.7c7,0,10.8,5.3,11.5,12.5A47.56,47.56,0,0,1,809.6,99c16.6,0,25.5,6,32.7,17.6,1.4-1,17.3-17.6,39.7-17.6,32.2,0,39.9,20.9,39.9,49.5v58.7a5.34,5.34,0,0,1-5.3,5.3h-7.2a5.34,5.34,0,0,1-5.3-5.3V148.5c0-20.7-6.7-33.4-23.6-33.4-18,0-28.1,11.8-32.2,15.1a101.87,101.87,0,0,1,1.4,18.3v58.7a5.34,5.34,0,0,1-5.3,5.3h-7.2a5.47,5.47,0,0,1-5.3-5.3V148.5c0-21.2-7-33.4-25-33.4-19.7,0-29.8,15.4-29.8,15.4v76.7a5.47,5.47,0,0,1-5.3,5.3h-7.2a5.34,5.34,0,0,1-5.3-5.3Zm234.3-5.8c29.1,0,50.2,21.4,50.2,50.5a49.13,49.13,0,0,1-.5,6.7,5.19,5.19,0,0,1-5,4.8H957.6c.5,18.5,16.1,38,36.3,38a52.47,52.47,0,0,0,30-9.1c3.8-2.6,5.5-3.6,8.4.7l2.9,4.1c2.2,3.4,3.1,4.6-1,7.7-6.7,5.3-21.4,12.8-40.6,12.8-33.7,0-53.9-26.5-53.9-58.2C939.8,125.6,960,98.9,993.7,98.9Zm32.4,47.8c-.5-16.4-13.5-31.7-32.4-31.7a36.32,36.32,0,0,0-35.8,31.7Z"
      />
    </svg>
  );
};

export default Logo;
