import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import moment from 'moment';
import { useSpring } from 'react-spring';

import data from '../../data.json';

import * as v from 'Utils/variables';
import colors from 'Utils/theme';
import { pandoraEvent, jungroupEvent } from 'Utils/analytics';

import { DefaultContext } from 'Context/DefaultContext';
import { StateContext } from 'Context/StateContext';
import { DispatchContext } from 'Context/DispatchContext';

import { availabilityDates } from 'Api/addressFetchAPI';

import useWindowSize from 'Hooks/useWindowSize';
import useFetch from 'Hooks/useFetch';

import Subscribe from 'Components/subscribe';
import ErrorBanner from 'Components/errorBanner';
import LoadingSpinner from 'Components/loadingSpinner';
import CallToAction from 'Components/callToAction';
import Link from 'Components/link';

import { responsiveFont } from 'Styles/helpers';

import Header from './components/Header';
import AutoCompleteInput from './components/AutoCompleteInput';
import AddressesToggle from './components/AddressToggle';

import ArrowRight from 'Icons/ArrowRight';

const StyledWrapper = styled.div`
  color: ${colors.white};
  padding: 0 0 24px;
  z-index: 1;

  @media (min-width: ${v.minTablet}) {
    width: 100%;
    max-width: 785px;
  }

  @media (min-width: ${v.max1025}) {
    margin: 0;
    border: none;
    padding: 30px 0;
  }

  @media (min-width: ${v.min1280}) {
    margin: 0;
    border: none;
    padding: 50px 0;
    max-width: 785px;
  }

  @media (min-width: ${v.min1440}) {
    max-width: 950px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;

  @media (min-width: ${v.minTablet}) {
    padding: 0;
    min-height: 0;
    height: 100%;
  }
`;

export const InnerContent = styled.div`
  padding: 0;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  @media (min-width: ${v.minTablet}) {
    min-height: 196px;
  }
`;

const BackButton = styled.button`
  display: inline-block;
  cursor: pointer;
  margin: 0 0 20px;
  font-size: 16px;
  text-align: left;
  text-decoration: underline;
  color: ${colors.walmart.blue};

  svg {
    transform: rotate(180deg);
    height: 18px;
    width: auto;

    @media (min-width: ${v.minTablet}) {
      height: 24px;
    }
  }
`;

const SubscribeWrapper = styled.div`
  width: 100%;
  position: relative;

  @media (min-width: ${v.minTablet}) {
    margin: 20px 0 0;
  }
`;

const GiftCardTermsAndConditions = styled.div`
  margin-left: 0px;
  padding-top: 5px;
  padding-bottom: 0px;
  color: ${colors.white};
  ${responsiveFont({ sizes: '13, 14' })};

  sup {
    font-size: 0.666em;
    padding-right: 4px;
  }

  a {
    color: ${colors.white};
    text-decoration: underline;
    ${responsiveFont({ sizes: '13, 14' })};
  }
`;

const AvailabilityChecker = ({ toggle, isVisible, hideHeaderFn = () => {}, showCopy = true }) => {
  const { inHomeBaseApiUrl, isAuthenticated, useProd } = useContext(DefaultContext);
  const { userState, addressesState } = useContext(StateContext);
  const { dispatch } = useContext(DispatchContext);

  const { hasInHome } = userState;
  const { addresses, storedAddress } = addressesState;

  const router = useRouter();

  const { data: addressesData, errors: addressesErrors, loading: addressesLoading } = useFetch(
    `${inHomeBaseApiUrl}/users/CID/address`,
    {
      credentials: 'include',
      headers: {
        'x-customer-version': '100.100.100',
      },
      method: 'GET',
    },
    !addresses && isAuthenticated,
  );

  useEffect(() => {
    if (addressesData) {
      dispatch({ type: 'UPDATE_ADDRESSES', payload: addressesData.addresses });
    }
  }, [addressesData]);

  const { windowWidth } = useWindowSize();

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (router.query.openDrawer) {
      !isVisible && toggle();
      router.replace(router.pathname);
    }
    router.pathname.includes('/get-started') && pandoraEvent('content');
  }, []);

  const getAvailableDates = (address) => {
    setIsSubmitted(false);
    setLoading(true);
    if (address && address.eligible) {
      availabilityDates(address.storeId, useProd).then((data) => {
        if (typeof data.errors === 'undefined' || data.errors.length > 0) {
          setSoftErrors(data.errors || [{ message: 'Oops, something went wrong. Please try again.' }]);

          let timeout = setTimeout(() => {
            setSoftErrors([]);
          }, 4300);

          setLoading(false);
          return () => clearTimeout(timeout);
        }
        setLoading(false);
        const isKitchenEarliestInstall = moment(data.data.kitchen).isBefore(data.data.garage);
        const earliestInstallData = Object.assign({}, data.data, { earliestInstall: isKitchenEarliestInstall ? data.data.kitchen : data.data.garage });
        setSelectedAddress({ ...address, installationDates: earliestInstallData });
        dispatch({ type: 'SET_STORED_ADDRESS', payload: { ...address, installationDates: earliestInstallData } });
        sessionStorage.setItem('address', JSON.stringify({ ...address, installationDates: earliestInstallData }));
        setAddNewAddress(false);
      });
    } else {
      setLoading(false);
      setSelectedAddress({ ...address, installationDates: { earliestInstall: null } });
      dispatch({ type: 'SET_STORED_ADDRESS', payload: { ...address, installationDates: { earliestInstall: null } } });
      sessionStorage.setItem('address', JSON.stringify({ ...address, installationDates: { earliestInstall: null } }));
    }
  };

  const [addNewAddress, setAddNewAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  useEffect(() => {
    if (storedAddress !== undefined && storedAddress && !addNewAddress && !selectedAddress) {
      setSelectedAddress(storedAddress);
    }

    if (storedAddress !== undefined && !storedAddress && !addNewAddress && addresses && addresses.length > 0 && addresses.find((address) => address.eligible)) {
      getAvailableDates(addresses.find((address) => address.eligible === true));
    }
  }, [addresses, storedAddress]);

  useEffect(() => {
    if (selectedAddress) {
      hideHeaderFn(true);
      router.pathname.includes('/get-started') && pandoraEvent('signup');
    } else {
      hideHeaderFn(false);
    }
  }, [selectedAddress]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [addressBook, setAddressBook] = useState(false);
  const [ctaTop, setCtaTop] = useState(0);
  const ctaRef = useCallback((node) => {
    if (node !== null) {
      setCtaTop(Math.round(node.getBoundingClientRect().top - (windowWidth <= 1024 ? 70 : 100)));
    }
  }, []);

  const [, setY] = useSpring(() => ({ y: 0 }));

  let isStopped = false;
  const onWheel = () => {
    isStopped = true;
    window.removeEventListener('wheel', onWheel);
  };

  useEffect(() => {
    if (addNewAddress && ctaTop < window.scrollY) {
      window.addEventListener('wheel', onWheel);

      setY({
        y: ctaTop,
        reset: true,
        from: { y: window.scrollY },
        duration: 100,
        onRest: () => {
          isStopped = false;
          window.removeEventListener('wheel', onWheel);
        },
        onFrame: (props) => {
          if (!isStopped) {
            window.scroll(0, props.y);
          }
        },
      });
    }
    return () => window.removeEventListener('wheel', onWheel);
  }, [addNewAddress]);

  const [loading, setLoading] = useState(false);

  const [softErrors, setSoftErrors] = useState([]);

  const tabIndex = isVisible ? 0 : -1;
  const { availabilityChecker: page } = data;

  const [stayInTouch, setStayInTouch] = useState(false);

  const [badAddress, setBadAddress] = useState(false);

  if (!mounted) {
    return null;
  }

  const headerCopy = () => {
    if (hasInHome) {
      return {
        main: "Let's check off that shopping list",
        subHeading: "Get more done at home with inHome, while you're not home. Order your groceries online and have us deliver.",
      };
    } else {
      if (selectedAddress && !badAddress) {
        if (selectedAddress.eligible) {
          return {
            main: <>We deliver to you! Sign up &amp; get $25</>,
            subHeading: (
              <>
                Start your free trial<sup>3</sup> & get a $25 gift card<sup>4</sup> with your first InHome order! Stay for just $19.95/mo or $148/yr after.
              </>
            ),
          };
        } else {
          if (selectedAddress.eligible !== null) {
            return {
              main: page.failedMessage,
              subHeading: <>Be the first to know when InHome makes it to {`${selectedAddress.addressLineOne}, ${selectedAddress.city}, ${selectedAddress.state} ${selectedAddress.postalCode}.`}</>,
            };
          } else {
            return {
              main: page.failedMessage,
              subHeading: 'Be the first to know when InHome makes it to your area.',
            };
          }
        }
      } else {
        return {
          main: (
            <>
              Try a free 30 days<sup>1</sup> of unlimited delivery into your home
            </>
          ),
          subHeading: <strong>See if we deliver to you:</strong>,
        };
      }
    }
  };

  const errors = softErrors || addressesErrors;

  return (
    <>
      {errors.length < 0 && <ErrorBanner errors={errors[0].errors} />}
      <StyledWrapper ref={ctaRef} isLoading={loading || addressesLoading} isEligible={selectedAddress && selectedAddress.eligible && !addNewAddress} isSubmitted={isSubmitted}>
        <Content>
          {loading || addressesLoading ? (
            <LoadingWrapper>
              <LoadingSpinner color={colors.white} />
            </LoadingWrapper>
          ) : (
            <>
              {!hasInHome && selectedAddress && !badAddress && !addNewAddress && (
                <BackButton
                  eligible={selectedAddress && selectedAddress.eligible}
                  onClick={() => {
                    setSelectedAddress(null);
                    dispatch({ type: 'SET_STORED_ADDRESS', payload: null });
                    setAddNewAddress(true);
                    setIsSubmitted(false);
                    setStayInTouch(false);
                  }}
                >
                  <ArrowRight fill={colors.white} />
                </BackButton>
              )}

              {(showCopy || (!showCopy && selectedAddress)) && !isSubmitted && <Header headerCopy={headerCopy()} inCta={true} eligible={selectedAddress?.eligible} />}

              {!hasInHome && isAuthenticated && addresses?.length > 0 && addNewAddress && !stayInTouch && !selectedAddress && (
                <AddressesToggle addressBook={addressBook} setAddressBook={(bool) => setAddressBook(bool)} inCta={true} setAddNewAddress={(bool) => setAddNewAddress(bool)} getAvailableDates={(a) => getAvailableDates(a)} />
              )}

              {!hasInHome && (addNewAddress || !selectedAddress) && !addressBook && !stayInTouch && (
                <AutoCompleteInput setAddNewAddress={(bool) => setAddNewAddress(bool)} getAvailableDates={(a) => getAvailableDates(a)} setLoading={(bool) => setLoading(bool)} setSoftErrors={(bool) => setSoftErrors(bool)} badAddress={badAddress} setBadAddress={(bool) => setBadAddress(bool)} />
              )}

              {!hasInHome && ((selectedAddress && !selectedAddress.eligible && !addNewAddress) || stayInTouch) && (
                <SubscribeWrapper>
                  <Subscribe
                    placeholder={windowWidth < 720 ? 'Your email' : 'Enter your email address'}
                    postalCode={selectedAddress.postalCode}
                    eligible={selectedAddress.eligible}
                    style={{ marginTop: 0 }}
                    inCta={true}
                    stayInTouch={stayInTouch}
                    setIsSubmitted={(bool) => setIsSubmitted(bool)}
                    leadSource={`eligibility_check`}
                  />
                </SubscribeWrapper>
              )}

              {selectedAddress && selectedAddress.eligible && !addNewAddress && !stayInTouch && (
                <>
                  <CallToAction
                    onClick={() => !hasInHome && jungroupEvent('get_started')}
                    tabIndex={tabIndex}
                    href={hasInHome ? 'https://grocery.walmart.com' : '/checkout'} // TODO: change back to /schedule after covid-19 crisis
                    target={hasInHome && '_blank'}
                    backgroundColor={colors.walmart.yellow}
                    textColor={colors.walmart.blue}
                    style={{ margin: '30px 0', maxWidth: windowWidth >= 720 ? '309px' : 'inherit' }}
                  >
                    {/* TODO: change back to Schedule now after covid-19 emergency */}
                    {hasInHome ? 'Shop grocery' : 'Get started'}
                  </CallToAction>
                </>
              )}

              {!hasInHome && !isSubmitted && selectedAddress && selectedAddress.eligible && (
                <GiftCardTermsAndConditions>
                  <sup>3</sup>Current Walmart+ members not eligible for InHome free trial. <Link href="/fyi/walmart-plus">Learn more</Link> about upgrading membership.
                </GiftCardTermsAndConditions>
              )}
            </>
          )}
        </Content>
      </StyledWrapper>
    </>
  );
};

export default AvailabilityChecker;
