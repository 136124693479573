import { useState, useEffect } from 'react';

const useFocusMethod = () => {
  const [isMouseMethod, setMouseMethod] = useState(false);

  const handleFocusMethod = (value = true) => {
    setMouseMethod(value);

    if (value) {
      document.body.classList.add('focus-with-mouse');
    } else {
      document.body.classList.remove('focus-with-mouse');
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleFocusMethod);
    window.addEventListener('keydown', () => handleFocusMethod(false));

    return () => {
      window.removeEventListener('mousedown', handleFocusMethod);
      window.removeEventListener('keydown', () => handleFocusMethod(false));
    };
  }, []);

  return isMouseMethod;
};

export default useFocusMethod;
