import React from 'react';

import colors from 'Utils/theme';

const Check = ({ fill = colors.inHome.highlightGreen }) => {
  return (
    <svg fill={fill} viewBox="0 0 48 48">
      <path d="M18.7,35.8l-8.2-8.3l3-3l5.2,5.2l15.8-15.8l3,3L18.7,35.8z" />
    </svg>
  );
};

export default Check;
