import React from 'react';
import PropTypes from 'prop-types';
import colors from 'Utils/theme';

const SparkLogo = ({ fill = colors.walmart.yellow }) => {
  return (
    <svg viewBox="0 0 48 48" fill={fill}>
      <path
        d="M24,18.7L24,18.7c-0.8,0-1.4-0.4-1.5-0.9l-0.8-8.5c0-0.8,1-1.5,2.2-1.5c1.2,0,2.2,0.7,2.2,1.5l-0.8,8.5
	C25.4,18.3,24.8,18.7,24,18.7 M19.4,21.3c0.4-0.7,0.4-1.4,0-1.7l-7-4.9c-0.7-0.4-1.8,0.1-2.4,1.2c-0.6,1.1-0.5,2.3,0.2,2.7l7.7,3.6
	C18.4,22.3,19,22,19.4,21.3L19.4,21.3 M28.6,21.3c0.4,0.7,1,1,1.5,0.8l7.8-3.6c0.7-0.4,0.8-1.6,0.2-2.7c-0.6-1.1-1.7-1.6-2.4-1.2
	l-7,4.9C28.3,19.9,28.2,20.7,28.6,21.3L28.6,21.3 M24,29.3L24,29.3c-0.8,0-1.4,0.4-1.5,0.9l-0.8,8.5c0,0.8,1,1.5,2.2,1.5
	c1.2,0,2.2-0.7,2.2-1.5l-0.8-8.5C25.4,29.7,24.8,29.3,24,29.3 M28.6,26.7L28.6,26.7c-0.4,0.7-0.4,1.4,0.1,1.7l7,4.9
	c0.7,0.4,1.8-0.1,2.4-1.2c0.6-1.1,0.5-2.3-0.2-2.7l-7.8-3.6C29.6,25.7,29,26,28.6,26.7 M19.4,26.7L19.4,26.7c-0.4-0.7-1-1-1.5-0.8
	l-7.7,3.6c-0.7,0.4-0.8,1.6-0.2,2.7c0.6,1.1,1.7,1.6,2.4,1.2l7-4.9C19.7,28.1,19.8,27.3,19.4,26.7"
      />
    </svg>
  );
};

SparkLogo.propTypes = {
  fill: PropTypes.string,
};

export default SparkLogo;
