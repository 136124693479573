import { defaultFetch } from '../api/defaultFetch';

export const addAddress = async (address, useProd) => {
  return defaultFetch(`${useProd ? process.env.NEXT_PUBLIC_PROD_API_URL : process.env.NEXT_PUBLIC_API_URL}/users/CID/address`, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'x-customer-version': '100.100.100',
    },
    method: 'POST',
    body: JSON.stringify(address),
  })
    .then(({ data }) => {
      return { data, errors: [] };
    })
    .catch(({ errors }) => {
      return { data: null, errors };
    });
};

export const eligibilityCheck = async (address, useProd) => {
  return defaultFetch(`${useProd ? process.env.NEXT_PUBLIC_PROD_API_URL : process.env.NEXT_PUBLIC_API_URL}/address/eligible`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(address),
  })
    .then(({ data }) => {
      return { data, errors: [] };
    })
    .catch(({ errors, status }) => {
      return { data: null, errors, status };
    });
};

export const availabilityDates = async (storeId, useProd) => {
  return defaultFetch(`${useProd ? process.env.NEXT_PUBLIC_PROD_API_URL : process.env.NEXT_PUBLIC_API_URL}/stores/${storeId}/installations/availability`, {
    method: 'GET',
  })
    .then(({ data }) => {
      return { data, errors: [] };
    })
    .catch(({ errors }) => {
      return { data: null, errors };
    });
};
