import React from 'react';
import colors from 'Utils/theme';

const Twitter = ({ fill = colors.inHome.blue }) => {
  return (
    <svg className="twitter" viewBox="0 0 46 36.79" fill={fill}>
      <path
        d="M1,38.25c.35,0,.7.06,1,.07s.77,0,1.16,0h.06A19,19,0,0,0,15,34.41a9.05,9.05,0,0,1-5.47-1.86,9.57,9.57,0,0,1-3.37-4.61l.89.11c.3,0,.6.05.89.05A10.89,10.89,0,0,0,9.21,28a9.59,9.59,0,0,0,1.21-.24A9.39,9.39,0,0,1,5,24.59a8.91,8.91,0,0,1-2.15-5.93v-.11l-.06-.05a10.58,10.58,0,0,0,2.05.86,7.74,7.74,0,0,0,2.27.32,9.36,9.36,0,0,1-3-3.26,9.06,9.06,0,0,1-.81-6.93,9.7,9.7,0,0,1,.95-2.21l0,0A26,26,0,0,0,8.1,11.16a26.51,26.51,0,0,0,4.64,3,28.91,28.91,0,0,0,5.24,2,24.16,24.16,0,0,0,5.66.87c-.07-.33-.13-.67-.19-1a7.85,7.85,0,0,1-.08-1.08,8.85,8.85,0,0,1,2.78-6.55A9.23,9.23,0,0,1,32.87,5.6a9.11,9.11,0,0,1,3.8.79,9.64,9.64,0,0,1,3,2.13,21.63,21.63,0,0,0,3.13-.89,20.34,20.34,0,0,0,2.91-1.38l0,.11a9.67,9.67,0,0,1-1.6,2.89,8.89,8.89,0,0,1-2.41,2.12,18.47,18.47,0,0,0,2.76-.53A23.59,23.59,0,0,0,47,10L47,10a19.29,19.29,0,0,1-2.08,2.53,20.33,20.33,0,0,1-2.56,2.21V16a27.17,27.17,0,0,1-1.73,9.33,27,27,0,0,1-5.18,8.5A26.66,26.66,0,0,1,27,40,26.64,26.64,0,0,1,15.44,42.4h0a26.28,26.28,0,0,1-7.66-1.11,27.22,27.22,0,0,1-6.73-3Z"
        transform="translate(-1 -5.6)"
      />
    </svg>
  );
};

export default Twitter;
