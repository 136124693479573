const MembershipReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_MEMBERSHIP':
      return {
        ...state,
        membership: action.payload,
      };
    case 'SET_CANCELED_MEMBERSHIP':
      return {
        ...state,
        canceledMembership: action.payload,
      };
    case 'SET_EXISTING_MEMBERSHIP_DATA':
      return {
        ...state,
        existingMembershipData: action.payload,
      };
    case 'SET_WALMART_PLUS_REFUND_AMOUNT':
      return {
        ...state,
        walmartPlusRefundAmount: action.payload,
      };
    default:
      return state;
  }
};

export default MembershipReducer;
