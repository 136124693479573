import React from 'react';
import colors from 'Utils/theme';

const YouTube = ({ fill = colors.inHome.blue }) => {
  return (
    <svg className="youtube" viewBox="0 0 46 34.47" fill={fill}>
      <path
        d="M19.25,30.37l12.43-6.88L19.25,16.57Zm27.3-16.18.22,2.48c.15,1.65.23,3.47.23,5.46v3.74c0,2-.08,3.81-.23,5.46l-.22,2.48-.41,1.87a6.38,6.38,0,0,1-1.41,3,5,5,0,0,1-2.63,1.72c-.88.17-1.54.28-2,.35-1.62.14-3.37.24-5.26.31l-5.23.15c-1.6,0-2.94.05-4,.05H22.08c-1.28,0-2.79-.07-4.53-.1s-3.46-.09-5.18-.16-3.08-.13-4-.2l-2.3-.4a5,5,0,0,1-2.81-1.72,6.38,6.38,0,0,1-1.41-3l-.41-1.87-.22-2.48C1.08,29.68,1,27.86,1,25.87V22.13c0-2,.08-3.81.23-5.46l.22-2.48.41-1.87a6.38,6.38,0,0,1,1.41-3A5.08,5.08,0,0,1,5.9,7.6a15.46,15.46,0,0,1,2-.33C9.49,7.13,11.24,7,13.13,7l5.23-.15c1.6,0,2.94,0,4,0h3.24c1.08,0,2.42,0,4,0L34.87,7c1.89.07,3.64.17,5.26.31a15.46,15.46,0,0,1,2,.33,5.08,5.08,0,0,1,2.63,1.74,6.38,6.38,0,0,1,1.41,3Z"
        transform="translate(-1 -6.76)"
      />
    </svg>
  );
};

export default YouTube;
