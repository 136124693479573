import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { math, stripUnit } from 'polished';

import { verticallyResponsive } from 'Styles/helpers';
import { segmentPadding, segmentPaddingMobile, minTablet, pageLimit } from 'Utils/variables';

import Container from '../container';

const segment = (props) => {
  const { overflowHidden, paddingTop = segmentPadding, paddingBottom = segmentPadding, paddingTopMobile = segmentPaddingMobile, paddingBottomMobile = segmentPaddingMobile } = props;

  const paddingTopUnitless = stripUnit(paddingTop);
  const paddingBottomUnitless = stripUnit(paddingBottom);
  const paddingTopMobileUnitless = stripUnit(paddingTopMobile);
  const paddingBottomMobileUnitless = stripUnit(paddingBottomMobile);

  return css`
    flex-grow: 1;
    width: 100%;
    overflow: ${overflowHidden ? 'hidden' : 'visible'};

    ${verticallyResponsive('padding-top', `${paddingTopMobileUnitless}px`)}
    ${verticallyResponsive('padding-bottom', `${paddingBottomMobileUnitless}px`)}

    @media (min-width: ${minTablet}) {
      ${verticallyResponsive('padding-top', `${stripUnit(math(`(${paddingTopUnitless}px / ${pageLimit}) * 100`))}%`)}
      ${verticallyResponsive('padding-bottom', `${stripUnit(math(`(${paddingBottomUnitless}px / ${pageLimit}) * 100`))}%`)}
    }

    @media (min-width: ${pageLimit}) {
      ${verticallyResponsive('padding-top', `${paddingTopUnitless}px`)}
      ${verticallyResponsive('padding-bottom', `${paddingBottomUnitless}px`)}
    }
  `;
};

export const Wrapper = styled.div`
  ${segment}
	overflow: ${(props) => (props.overflowHidden ? 'hidden' : undefined)};
`;

const Segment = ({ overflowHidden = false, container = true, children, ...rest }) => (
  <Wrapper overflowHidden={overflowHidden} {...rest}>
    {container ? <Container>{children}</Container> : children}
  </Wrapper>
);

export default Segment;
