import { useState, useEffect } from 'react';

/**
 * Custom hooks that return the keys that are pressed on the keyboard
 */

const useKeyDown = (callback) => {
  const [keys, setKeys] = useState([]);

  const handleKeyDown = (e) => {
    if (keys.includes(e.keyCode)) {
      return;
    }

    if (callback) {
      callback(e, [...keys, e.keyCode]);
    }

    setKeys([...keys, e.keyCode]);
  };

  const handleKeyUp = (e) => {
    const d = keys.indexOf(e.keyCode, 0);
    setKeys([...keys.slice(0, d), ...keys.slice(d + 1)]);
  };

  useEffect(() => {
    /*
      passing { capture: true } due to React version 17 breaking change
      https://reactjs.org/blog/2020/08/10/react-v17-rc.html#fixing-potential-issues
    */
    document.addEventListener('keydown', handleKeyDown, { capture: true });
    document.addEventListener('keyup', handleKeyUp, { capture: true });

    return () => {
      document.removeEventListener('keydown', handleKeyDown, { capture: true });
      document.removeEventListener('keyup', handleKeyUp, { capture: true });
    };
  }, [keys]);

  return keys;
};

export default useKeyDown;
