import React, { useState, useEffect, useContext } from 'react';

import colors from 'Utils/theme';

import { defaultFetch } from 'Api/defaultFetch';

import { DefaultContext } from 'Context/DefaultContext';

import useWindowSize from 'Hooks/useWindowSize';

import useFormInput from 'Hooks/useFormInput';
import validation from 'Components/forms/validation';
import { StyledButtonSpinner } from 'Components/forms/styles';
import ErrorBanner from 'Components/errorBanner';

import Check from 'Icons/Check';
import ArrowRight from 'Icons/ArrowRight';

import { Form, FormWrapper, MainContainer, Input, Field, Button, Label, PrivacyStatement, Disclaimer, ThankYouContainer, ThankYouH2, InlineLink, Validation, ZipCodeContainer, IncludeZipcodeContainer } from './styles';

const Subscribe = ({ postalCode, eligible, placeholder, inCta = false, roundedTexts = false, inFooter = false, stayInTouch = false, setIsSubmitted: setParentIsSubmitted = () => null, includeZipInput = false, leadSource = null, ...props }) => {
  const { windowWidth } = useWindowSize();
  const { inHomeBaseApiUrl } = useContext(DefaultContext);

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [softErrors, setSoftErrors] = useState([]);

  const email = useFormInput({
    validators: [validation.isEmail({ message: 'Please enter a valid email address' })],
  });

  const zipCode = useFormInput({
    validators: [validation.isMinLength({ message: 'Please enter a valid zip code', length: 5 }), validation.isRequired({ message: 'ZIP code is required' })],
  });

  useEffect(() => {
    if (props.setSubscribed && submitted) {
      props.setSubscribed(true);
    }
  }, [submitted]);

  const [emptySubmit, setEmptySubmit] = useState(false);
  const [emptyZipcodeSubmit, setEmptyZipcodeSubmit] = useState(false);
  const [compactButton, setCompactButton] = useState(false);

  useEffect(() => {
    emptySubmit && setEmptySubmit(false);
    email.value.length > 20 && inFooter ? setCompactButton(true) : setCompactButton(false);
  }, [email.value]);

  useEffect(() => {
    emptyZipcodeSubmit && setEmptyZipcodeSubmit(false);
  }, [zipCode.value]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email.isValid || email.isEmpty) {
      if (email.isEmpty) {
        setEmptySubmit(true);
      }
      return;
    }

    if (includeZipInput) {
      if (!zipCode.isValid || zipCode.isEmpty) {
        if (zipCode.isEmpty) {
          setEmptyZipcodeSubmit(true);
        }
        return;
      }
    }

    const emailBody = {
      email: email.value,
      zipcode: zipCode.value || postalCode,
      eligible,
      lead_source: leadSource,
      // city: TODO
    };

    setLoading(true);

    defaultFetch(`${inHomeBaseApiUrl}/users/leads`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'x-customer-version': '100.100.100',
      },
      method: 'POST',
      body: JSON.stringify(emailBody),
    })
      .then(() => setLoading(false) + setSubmitted(true) + setParentIsSubmitted(true))
      .catch(({ errors }) => setSoftErrors(errors));
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)} aria-live={`polite`}>
      {softErrors && softErrors.length > 0 && <ErrorBanner errors={softErrors} />}
      {!submitted ? (
        <MainContainer>
          <FormWrapper inCta={inCta}>
            <Field inCta={inCta}>
              {includeZipInput ? (
                <>
                  <IncludeZipcodeContainer>
                    <Input inCta={inCta} {...email} name="email" type="email" placeholder={placeholder} tabIndex={inFooter ? -1 : 0} />
                    <ZipCodeContainer>
                      <Input inCta={inCta} {...zipCode} name="zipcode" type="text" maxLength="5" placeholder="Zip code" tabIndex={inFooter ? -1 : 0} />
                      <Button className="rounded" tabIndex={0} type="submit" inCta={inCta} inFooter={inFooter} compactButton={compactButton} includeZipInput={includeZipInput}>
                        {loading ? <StyledButtonSpinner /> : inFooter ? compactButton ? <ArrowRight fill={colors.walmart.blue} /> : 'Join' : inCta ? <ArrowRight fill={colors.walmart.blue} /> : 'Submit'}
                      </Button>
                    </ZipCodeContainer>
                  </IncludeZipcodeContainer>
                  <Label style={{ fontSize: '14px' }}>{(email.isValid || email.isEmpty) && !emptySubmit ? '' : <Validation>{emptySubmit ? 'Please enter an email address' : email.errorMessage}</Validation>}</Label>
                  <Label style={{ fontSize: '14px' }}>{(zipCode.isValid || zipCode.isEmpty) && !emptyZipcodeSubmit ? '' : <Validation>{emptyZipcodeSubmit ? 'Please enter a zip code' : zipCode.errorMessage}</Validation>}</Label>
                </>
              ) : (
                <>
                  <Input inCta={inCta} {...email} name="email" type="email" placeholder={placeholder} {...props} tabIndex={inFooter ? -1 : 0} />
                  <Button className="rounded" tabIndex={0} type="submit" inCta={inCta} inFooter={inFooter} compactButton={compactButton}>
                    {loading ? <StyledButtonSpinner /> : inFooter ? compactButton ? <ArrowRight fill={colors.walmart.blue} /> : 'Join' : inCta ? <ArrowRight fill={colors.walmart.blue} /> : 'Submit'}
                  </Button>
                  <Label style={{ fontSize: '14px' }}>{(email.isValid || email.isEmpty) && !emptySubmit ? '' : <Validation>{emptySubmit ? 'Please enter an email address' : email.errorMessage}</Validation>}</Label>
                </>
              )}
              {!inFooter ? (
                <PrivacyStatement inCta={inCta} roundedTexts={roundedTexts}>
                  By clicking ‘Submit’, you agree to the InHome <InlineLink href="https://corporate.walmart.com/privacy-security/walmart-privacy-policy">Privacy Policy</InlineLink>.
                </PrivacyStatement>
              ) : (
                <Disclaimer>Get the latest on Walmart InHome in your city.</Disclaimer>
              )}
            </Field>
          </FormWrapper>
        </MainContainer>
      ) : (
        <ThankYouContainer inCta={inCta}>
          {inCta && <ThankYouH2 inCta={inCta}>{stayInTouch ? `We're heading to your inbox!` : `You're on the list!`}</ThankYouH2>}

          <span>
            {stayInTouch ? (
              `Check your email soon for your first InHome update.`
            ) : (
              <>
                {!inCta && (
                  <>
                    <Check fill={colors.walmart.yellow} /> Thanks!{' '}
                  </>
                )}
                We’ll let you know when InHome
                {!inCta || windowWidth <= 720 ? <br /> : ' '}
                is in your area.
              </>
            )}
          </span>
        </ThankYouContainer>
      )}
    </Form>
  );
};

export default Subscribe;
