import React from 'react';

import { getSlugs } from 'Utils';

import Logo from 'Icons/Logo';
import LogoGrocery from 'Icons/LogoGrocery';
import LogoReturns from 'Icons/LogoReturns';
import LogoPharmacy from 'Icons/LogoPharmacy';
import InHomeLennarLogo from 'Pages/landing-lennar/InHomeLennarLogo';

import HeaderStandaloneComponent from 'Components/header-standalone';

const HeaderStandalone = ({ pathname }) => {
  const HeaderLogo = (props) => {
    switch (getSlugs(pathname)[0]) {
      case 'landing-grocery':
        return <LogoGrocery {...props} />;
      case 'landing-returns':
        return <LogoReturns {...props} />;
      case 'landing-pharmacy':
        return <LogoPharmacy {...props} />;
      case 'landing-lennar':
        return <InHomeLennarLogo {...props} />;
      default:
        return <Logo {...props} />;
    }
  };

  const isPartner = ['landing-lennar'].includes(getSlugs(pathname)[0]);

  return (
    <>
      <HeaderStandaloneComponent logo={(props) => <HeaderLogo {...props} />} isPartner={isPartner} />
    </>
  );
};

export default HeaderStandalone;
