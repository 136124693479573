import React, { useState, useEffect, useContext, useRef } from 'react';
import { isIE } from 'react-device-detect';
import { useRouter } from 'next/router';
import moment from 'moment';
import { WebChat } from '@cyborg/webchat';
// import '@cyborg/webchat/wm-inhome-botchat.css';

import Error from '../pages/_error';

import { errorPageList, getCookie, getSpidCidHeader, getSlugs, generateRandomId } from 'Utils';

import { DefaultContext } from 'Context/DefaultContext';
import { StateContext } from 'Context/StateContext';
import { DispatchContext } from 'Context/DispatchContext';

import useMedia from 'Hooks/useMedia';
import useFetch from 'Hooks/useFetch';

import PreLoader from 'Components/preLoader';

const DefaultProvider = ({ children }) => {
  const { userState, addressesState } = useContext(StateContext);
  const { dispatch } = useContext(DispatchContext);

  const { user, hasInHome, currentHome } = userState;
  const { storedAddress } = addressesState;

  const chatRef = useRef(null);

  const router = useRouter();

  // Default
  const [mounted, setMounted] = useState(null);
  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  // default loading
  const [preloading, setPreloading] = useState(true);
  useEffect(() => {
    let preloadTimeout = setTimeout(() => {
      setPreloading(false);
    }, 2000);

    return () => clearTimeout(preloadTimeout);
  }, []);

  // CID and API settings
  const [cid, setCid] = useState();
  const [useProd, setUseProd] = useState();
  useEffect(() => {
    const hasCID = router.query.cid || getCookie('hasCID');
    const useProdSession = sessionStorage.getItem('productionApi') || 0;
    setUseProd(+useProdSession);
    setCid(hasCID);
  }, [router]);

  const [inHomeBaseApiUrl, setInHomeBaseApiUrl] = useState();
  const [walmartBaseApiUrl, setWalmartBaseApiUrl] = useState();
  const [loginUrl, setLoginUrl] = useState();
  const [logoutUrl, setLogoutUrl] = useState();
  const [defaultOfferId, setDefaultOfferId] = useState();
  const [gbOfferId, setGbOfferId] = useState();
  const [defaultPlanId, setDefaultPlanId] = useState();
  const [walmartPlusCheckoutURL, setWalmartPlusCheckoutURL] = useState();
  const [walmartURL, setWalmartURL] = useState();
  const [onTechLoginUrl, setOnTechLoginUrl] = useState();

  useEffect(() => {
    if (useProd !== undefined) {
      // this is only for staging to use prod endpoints
      if (useProd) {
        setInHomeBaseApiUrl(process.env.NEXT_PUBLIC_PROD_API_URL);
        setWalmartBaseApiUrl(process.env.NEXT_PUBLIC_PROD_WALMART_API_URL);
        setLoginUrl(process.env.NEXT_PUBLIC_PROD_LOGIN_URL);
        setLogoutUrl(process.env.NEXT_PUBLIC_PROD_LOGOUT_URL);
        setDefaultOfferId(process.env.NEXT_PUBLIC_PROD_OFFER_ID_DEFAULT);
        setGbOfferId(process.env.NEXT_PUBLIC_PROD_OFFER_ID_GB);
        setDefaultPlanId(+process.env.NEXT_PUBLIC_PROD_PLAN_ID);
        setWalmartPlusCheckoutURL(process.env.NEXT_PUBLIC_PROD_WMPLUS_CHECKOUT_URL);
        setWalmartURL(process.env.NEXT_PUBLIC_PROD_WM_URL);
        setOnTechLoginUrl(process.env.NEXT_PUBLIC_PROD_ONTECH_LOGIN_URL);
      } else {
        setInHomeBaseApiUrl(process.env.NEXT_PUBLIC_API_URL);
        setWalmartBaseApiUrl(process.env.NEXT_PUBLIC_WALMART_API_URL);
        setLoginUrl(process.env.NEXT_PUBLIC_LOGIN_URL);
        setLogoutUrl(process.env.NEXT_PUBLIC_LOGOUT_URL);
        setDefaultOfferId(process.env.NEXT_PUBLIC_OFFER_ID_DEFAULT);
        setGbOfferId(process.env.NEXT_PUBLIC_OFFER_ID_GB);
        setDefaultPlanId(+process.env.NEXT_PUBLIC_PLAN_ID);
        setWalmartPlusCheckoutURL(process.env.NEXT_PUBLIC_WMPLUS_CHECKOUT_URL);
        setWalmartURL(process.env.NEXT_PUBLIC_WM_URL);
        setOnTechLoginUrl(process.env.NEXT_PUBLIC_ONTECH_LOGIN_URL);
      }
    }
  }, [useProd]);

  const [isAuthenticated, setIsAuthenticated] = useState();
  useEffect(() => {
    cid === null && setIsAuthenticated(false);
    cid && setIsAuthenticated(true);
  }, [cid]);

  const [paymentStatus, setPaymentStatus] = useState();
  const [cancellationRequest, setCancellationRequest] = useState();

  useEffect(() => {
    if (hasInHome) {
      const main = document?.querySelector('#main');
      localStorage.setItem('hasInHome', 1);
      main && (main.style.top = '0');
    } else {
      localStorage.removeItem('hasInHome');
    }
  }, [hasInHome]);

  const { data: userData, errors: userErrors, loading: userLoading, refetch: refetchUser } = useFetch(
    `${inHomeBaseApiUrl}/v1/users/CID`,
    {
      credentials: 'include',
      headers: {
        'x-customer-version': '100.100.100',
      },
      method: 'GET',
    },
    isAuthenticated && inHomeBaseApiUrl,
  );

  useEffect(() => {
    if (userData) {
      const userHasMultipleHomes = userData?.homes?.length > 1;
      dispatch({ type: 'SET_USER', payload: { ...userData, userHasMultipleHomes } });
    }
  }, [userData]);

  useEffect(() => {
    if (user) {
      if (user.homes.length > 0) {
        const home = user.homes.find((home) => home.id === user.selected_home_id) || user.homes[0];
        dispatch({ type: 'CURRENT_HOME', payload: home });
        dispatch({ type: 'SET_HAS_INHOME', payload: true });

        if (home?.install_completed) {
          dispatch({ type: 'SET_HAS_DEVICE', payload: true });
        }
        setPaymentStatus(user.payment_method);
        setCancellationRequest(user.cancellation_request);
      } else {
        dispatch({ type: 'SET_HAS_INHOME', payload: false });
      }
    }
  }, [user]);

  const { data: devicesData, loading: loadingDevices } = useFetch(
    `${inHomeBaseApiUrl}/v1/users/CID/home/${currentHome?.id}/locks/eligible`,
    {
      credentials: 'include',
      headers: {
        'x-customer-version': '100.100.100',
      },
      method: 'GET',
    },
    userData && currentHome?.id,
  );

  useEffect(() => {
    if (devicesData) {
      dispatch({ type: 'ELIGIBLE_DEVICES', payload: devicesData.delivery_locations });
    }
  }, [devicesData]);

  useEffect(() => {
    if (mounted) {
      getSpidCidHeader();

      const timeStamp = moment().format();
      const sessionTimestamp = sessionStorage.getItem('session');
      const expired = moment(sessionTimestamp).isBefore(moment(timeStamp).subtract(12, 'h'));

      if (!sessionTimestamp) {
        sessionStorage.setItem('session', timeStamp);
      } else {
        if (expired) {
          sessionStorage.removeItem('session');
          sessionStorage.removeItem('address');
          sessionStorage.setItem('session', timeStamp);
          if (window && typeof window !== 'undefined') window.assign();
        }
      }
    }
  }, [mounted]);

  useEffect(() => {
    const addressSession = JSON.parse(sessionStorage.getItem('address'));

    if (addressSession && addressSession.installationDates) {
      dispatch({ type: 'SET_STORED_ADDRESS', payload: addressSession });
    } else {
      dispatch({ type: 'SET_STORED_ADDRESS', payload: false });
    }
  }, []);

  const handleRouteChange = () => {
    const sessionAddress = JSON.parse(sessionStorage.getItem('address'));

    if ((storedAddress && sessionAddress && storedAddress.addressLineOne !== sessionAddress.addressLineOne) || (!storedAddress && sessionAddress)) {
      dispatch({ type: 'SET_STORED_ADDRESS', payload: sessionAddress });
    }
  };

  const isMobile = useMedia('(max-width: 839px)');

  const [openChat, setOpenChat] = useState(false);
  useEffect(() => {
    if (openChat) chatRef.current.clickStartChatButton();
  }, [openChat]);

  // contexts
  const defaultContext = {
    isIE,
    isMobile,
    preloading,
    isAuthenticated,
    useProd,
    inHomeBaseApiUrl,
    walmartBaseApiUrl,
    loginUrl,
    logoutUrl,
    paymentStatus,
    cancellationRequest,
    userLoading,
    refetchUser,
    defaultPlanId,
    defaultOfferId,
    gbOfferId,
    walmartPlusCheckoutURL,
    walmartURL,
    onTechLoginUrl,
    startChat: () => setOpenChat(true),
    loadingDevices,
  };

  const loading = userLoading || useProd === undefined;

  if (!mounted) return null;

  if (userErrors && userErrors.length > 0 && errorPageList.includes(getSlugs(router.pathname)[0])) {
    return <Error errors={userErrors[0] && userErrors[0].errors} />;
  } else if (userErrors && userErrors.length > 0) {
    // eslint-disable-next-line no-console
    console.warn(`There was an issue fetching - ${JSON.stringify(userErrors)}`);
  }

  router.events.on('routeChangeStart', handleRouteChange);

  const chatUser = localStorage.getItem('chatUser') || generateRandomId(18);
  localStorage.setItem('chatUser', chatUser);

  if (loading || preloading) return <PreLoader loading={true} />;

  return (
    <DefaultContext.Provider value={defaultContext}>
      {children}
      <WebChat
        ref={chatRef}
        onClickClose={() => {
          setOpenChat(false);
          getSlugs(router.pathname).includes('chat') && router.push('/done');
        }}
        startChatButton={<></>}
        user={{ id: chatUser }}
        channelData={{ email: user?.username }}
        authUrl={process.env.NEXT_PUBLIC_CHAT_AUTH_URL}
        brand="inhome"
      />
    </DefaultContext.Provider>
  );
};

export default DefaultProvider;
