import React, { useRef, useEffect, useContext, useState } from 'react';
import { useRouter } from 'next/router';

import styled from '@emotion/styled';

import { DefaultContext } from 'Context/DefaultContext';

import useWindowSize from 'Hooks/useWindowSize';
import StickyFooter from 'Components/stickyFooter';
import useFeatureFlag from 'hooks/useFeatureFlag';

import { getSlugs } from 'Utils';

import data from '../../data';
import { StateContext } from 'context/StateContext';

const StyledMain = styled.main`
  position: relative;
`;

const MainContent = ({ children }) => {
  const { walmartPlusCheckoutURL } = useContext(DefaultContext);
  const mainRef = useRef(null);
  const { home: page } = data;

  const { windowWidth } = useWindowSize();
  const router = useRouter();

  const stickyFooterPages = ['grocery', 'returns', 'pharmacy', 'our-associates', 'fyi', 'how-it-works', 'landing-grocery', 'landing-pharmacy', 'landing-returns', 'landing-lennar'];

  const { featureFlagState } = useContext(StateContext);
  const maintenanceDisabledPages = ['account', 'checkout', 'get-started'];

  //Get All flags
  useFeatureFlag();

  //SiteDown Start
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (featureFlagState.featureFlags && featureFlagState.featureFlags.length > 0) {
      let flag = featureFlagState.featureFlags?.filter((i) => i.name === 'site_down')?.[0];

      if (flag?.active && maintenanceDisabledPages.includes(getSlugs(window.location.pathname)[0])) {
        setTimeout(() => setRender(true), 1000);
        router.push('/site-down');
      } else setRender(true);
    }
  }, [featureFlagState, router.pathname]);

  const StickyFooterCta = () => {
    const breakpointWidth = windowWidth <= 720;
    const url = window.location.pathname;
    const isStickyPage = stickyFooterPages.includes(getSlugs(url)[0]) || url === '/';

    if (!breakpointWidth || !isStickyPage) {
      return null;
    }

    return <StickyFooter zIndex={99} ctaText={page.cta.ctaText} href={walmartPlusCheckoutURL} />;
  };

  if (!render) return <></>;

  return (
    <StyledMain id="main" role="main" ref={mainRef}>
      {children}
      <StickyFooterCta />
    </StyledMain>
  );
};

export default MainContent;
