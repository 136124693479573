import React from 'react';
import colors from 'Utils/theme';

const CertificateIneligible = ({ primary = colors.inHome.grey, secondary = colors.white }) => {
  return (
    <svg viewBox="0 0 48 48">
      <path
        fill={primary}
        d="M3.19,26.79a4.73,4.73,0,0,0,0-5.59,4.71,4.71,0,0,1-.7-4.26,4.64,4.64,0,0,1,3-3A4.69,4.69,0,0,0,8.79,9.37a4.7,4.7,0,0,1,1.92-3.85,4.61,4.61,0,0,1,4.22-.64,4.42,4.42,0,0,0,1.48.25,4.66,4.66,0,0,0,3.8-2,4.62,4.62,0,0,1,7.58,0,4.66,4.66,0,0,0,3.8,2,4.42,4.42,0,0,0,1.48-.25,4.6,4.6,0,0,1,4.24.64,4.69,4.69,0,0,1,1.92,3.86,4.69,4.69,0,0,0,3.24,4.52,4.67,4.67,0,0,1,3,3,4.74,4.74,0,0,1-.7,4.25,4.74,4.74,0,0,0,0,5.6,4.72,4.72,0,0,1-2.34,7.3,4.68,4.68,0,0,0-3.26,4.53,4.71,4.71,0,0,1-1.92,3.87,4.62,4.62,0,0,1-4.24.63,4.64,4.64,0,0,0-5.28,1.74,4.62,4.62,0,0,1-7.58,0,4.63,4.63,0,0,0-5.28-1.74,4.62,4.62,0,0,1-4.24-.63,4.73,4.73,0,0,1-1.92-3.87,4.69,4.69,0,0,0-3.22-4.53,4.71,4.71,0,0,1-2.34-7.29Z"
      />
      <path
        fill={secondary}
        d="M25.13,25.09l6-6a.81.81,0,0,0,0-1.13A.8.8,0,0,0,30,18l-6,6-6-6a.8.8,0,0,0-1.13,0,.81.81,0,0,0,0,1.13l6,6-6,5.95a.83.83,0,0,0-.23.57.8.8,0,0,0,.23.56.8.8,0,0,0,.57.24.77.77,0,0,0,.56-.24l6-6,6,6a.77.77,0,0,0,.56.24.8.8,0,0,0,.57-.24.8.8,0,0,0,.23-.56.83.83,0,0,0-.23-.57Z"
      />
    </svg>
  );
};

export default CertificateIneligible;
