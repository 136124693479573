import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { math } from 'polished';

import { gutter } from 'Utils/variables';
import { generateMq } from 'Styles/helpers';

const column = (props) => {
  const medias = generateMq(props);

  return css`
    flex: none;
    align-self: stretch;

    padding-left: ${math(`${gutter} / 2`)};
    padding-right: ${math(`${gutter} / 2`)};

    ${medias};
  `;
};

export default styled.div`
  ${column}
`;
