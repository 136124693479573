import React from 'react';
import colors from 'Utils/theme';

const PlusUpLogo = ({ primary = colors.inHome.blue, secondary = colors.walmart.yellow, className, ...rest }) => {
  return (
    <svg viewBox="0 0 176 36" className={className} {...rest}>
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3814 12.195V13.7599H38.4263C38.9429 12.9222 39.7949 11.9351 41.6911 11.9351C43.1664 11.9351 44.2985 12.754 44.7802 13.9951H44.8251C45.2398 13.3699 45.7146 12.895 46.2448 12.5607C46.8755 12.1628 47.5731 11.9351 48.4568 11.9351C50.4525 11.9351 52.2203 13.3517 52.2203 16.9294V23.713H49.1755V17.402C49.1755 15.5887 48.5776 14.5398 47.3313 14.5398C46.4176 14.5398 45.7539 15.1693 45.5036 15.943C45.4171 16.2102 45.3769 16.5744 45.3769 16.8765V23.713H42.3341V17.1486C42.3341 15.6077 41.759 14.5398 40.53 14.5398C39.5468 14.5398 38.9312 15.3353 38.6787 16.0154C38.5712 16.3068 38.5358 16.6415 38.5358 16.9582V23.713H35.49V12.195H38.3814Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.9311 12.195V14.3675H68.0161C68.5731 12.7088 69.9001 11.9351 71.0666 11.9351C71.3593 11.9351 71.5201 11.9576 71.7553 12.0063V15.0221C71.4746 14.9804 71.2155 14.94 70.8531 14.94C69.5334 14.94 68.6136 15.7296 68.3824 16.9651C68.3335 17.2195 68.3147 17.4969 68.3147 17.8153V23.713H65.189V12.195H67.9311Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.9986 8.38062V12.0063H78.6374V14.94H75.9986V19.2671C75.9986 20.6673 76.3449 21.3465 77.3767 21.3465C77.8633 21.3465 78.2188 21.284 78.5279 21.206L78.5668 23.6388C78.1516 23.7977 77.27 23.9725 76.3744 23.9725C75.3205 23.9725 74.4415 23.5945 73.9075 23.0108C73.2967 22.3433 73.0107 21.2507 73.0107 19.6915V8.38062H75.9986Z"
      />
      <path fill={primary} fillRule="evenodd" clipRule="evenodd" d="M30.3379 23.7131H33.3676V8.38062H30.3379V23.7131Z" />
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5663 11.9351C27.232 11.9351 28.3169 14.2311 28.3169 16.8416V20.9375C28.3169 22.0013 28.3603 22.8844 28.4943 23.713C25.9419 24.4745 25.4785 22.4838 25.4785 22.4838H25.4097C24.7102 23.4002 23.5429 23.9724 22.1471 23.9724C19.8796 23.9724 18.5752 22.2905 18.5752 20.4959C18.5752 17.5563 21.137 16.1142 25.1928 16.1205V15.9555C25.1928 15.2588 24.8884 14.1723 23.0272 14.1771C21.9028 14.1771 20.7158 14.5772 19.9701 15.0601L19.3736 12.9622C20.1885 12.4784 21.6832 11.9351 23.5663 11.9351ZM25.29 18.1616L25.1589 18.1616C23.2846 18.1717 21.6656 18.6248 21.6656 20.1709C21.6656 21.2053 22.3318 21.6945 23.1702 21.6945C24.1762 21.6945 24.9804 21.0199 25.215 20.2219C25.2723 20.0302 25.29 19.8108 25.29 19.5985V18.1616Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.35134 8.38062C3.6639 10.0043 4.55429 14.7149 4.55429 14.7149C4.92715 16.652 5.27175 18.6826 5.53052 20.2885H5.57598C5.82859 18.5833 6.22589 16.9674 6.65035 14.9663L8.1492 8.38062H11.4647L12.8659 15.1364C13.2339 17.0161 13.5265 18.4638 13.7565 20.2118H13.8018C14.0549 18.4467 14.3829 16.9389 14.7447 15.0095L16.0905 8.38062H19.2729L15.3312 23.7131C12.8201 24.2786 11.858 23.2399 11.5123 21.5912C11.1673 19.9424 10.4943 16.8029 10.4943 16.8029C10.1491 15.0839 9.87947 13.8714 9.68987 12.1065H9.64261C9.37297 13.8539 9.09156 15.0787 8.67253 16.7964L7.01796 23.7131C4.45452 24.2376 3.56539 23.46 3.07193 21.3465C2.6491 19.5358 0 8.38062 0 8.38062H3.35134Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.417 11.9351C62.0837 11.9351 63.1685 14.2311 63.1685 16.8416V20.9375C63.1685 22.0013 63.2118 22.8844 63.3462 23.713C60.8443 24.4592 60.3494 22.5623 60.3306 22.4862L60.33 22.4838H60.261C59.5626 23.4002 58.395 23.9724 57.0001 23.9724C54.7312 23.9724 53.4265 22.2905 53.4265 20.4959C53.4265 17.5563 55.9885 16.1142 60.0435 16.1205V15.9555C60.0435 15.2588 59.7386 14.1723 57.8787 14.1771C56.7549 14.1771 55.5669 14.5772 54.822 15.0601L54.2249 12.9622C55.0403 12.4784 56.5347 11.9351 58.417 11.9351ZM60.1415 18.1616L60.0103 18.1616C58.1355 18.1717 56.5167 18.6248 56.5167 20.1709C56.5167 21.2053 57.1828 21.6945 58.0216 21.6945C59.0271 21.6945 59.8317 21.0199 60.0664 20.2219C60.123 20.0302 60.1415 19.8108 60.1415 19.5985V18.1616Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.4287 22.8967C84.4469 23.4533 85.0573 23.9955 85.8296 23.9955C86.6007 23.9955 87.2084 23.4533 87.2281 22.8993L87.3457 19.5246L90.6322 19.3996C91.1738 19.3812 91.7015 18.7539 91.7015 17.9603C91.7015 17.1678 91.1738 16.5433 90.6347 16.5232L87.3551 16.3972L87.2289 13.0253C87.2108 12.4687 86.6005 11.9265 85.8282 11.9265C85.0569 11.9265 84.4492 12.4687 84.4296 13.0227L84.3145 16.3879L81.0256 16.5225C80.4838 16.5409 79.9563 17.1681 79.9563 17.9618C79.9563 18.7543 80.4838 19.3787 81.0231 19.3988L84.3047 19.5239L84.4287 22.8967Z"
      />
      <path
        fill={secondary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.6607 8.58975L84.0644 1.64979C84.0013 0.948088 84.8448 0.399902 85.827 0.399902C86.8114 0.399902 87.6515 0.968184 87.5919 1.64979L86.9936 8.58975C86.97 9.02983 86.4299 9.3296 85.825 9.3296C85.2244 9.3296 84.6965 8.99689 84.6607 8.58975Z"
      />
      <path
        fill={secondary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.2406 23.646L98.7912 27.643C99.3493 28.0274 100.233 27.5715 100.727 26.6985C101.217 25.8228 101.153 24.8005 100.554 24.5016L94.4071 21.5651C94.0234 21.4159 93.503 21.6945 93.1997 22.2337C92.9001 22.7704 92.9242 23.3742 93.2406 23.646Z"
      />
      <path
        fill={secondary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.0577 13.5248C93.3591 14.0616 93.9036 14.3783 94.2654 14.1954L100.412 11.2546C101.024 10.9515 101.073 9.93552 100.585 9.06002C100.091 8.18452 99.2101 7.73087 98.6495 8.1153L93.0989 12.1167C92.7533 12.3711 92.7562 12.9855 93.0577 13.5248Z"
      />
      <path
        fill={secondary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.6607 27.4101L84.0644 34.35C84.0211 35.005 84.8448 35.5999 85.8271 35.5999C86.8114 35.5999 87.6477 35.0352 87.5919 34.35L86.9936 27.4101C86.9592 26.9862 86.4299 26.6704 85.8251 26.6704C85.2244 26.6726 84.6984 26.9968 84.6607 27.4101Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.568 8.81378C107.568 8.59235 107.762 8.39343 107.977 8.39343H108.838C109.053 8.39343 109.247 8.59235 109.247 8.81378V23.4574C109.247 23.6781 109.053 23.8773 108.838 23.8773H107.977C107.762 23.8773 107.568 23.6781 107.568 23.4574V8.81378Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.303 13.9678C112.303 13.7244 112.454 13.5694 112.691 13.5694C113.315 13.5694 113.659 14.0562 113.724 14.72C114.348 14.1668 115.511 13.4368 117.061 13.4368C119.945 13.4368 120.656 15.3614 120.656 17.9936V23.3906C120.656 23.6562 120.419 23.8772 120.182 23.8772H119.536C119.278 23.8772 119.063 23.6562 119.063 23.3906V17.7724C119.063 15.8256 118.417 14.9189 116.953 14.9189C115.08 14.9189 113.896 16.3346 113.896 16.3346V23.3906C113.896 23.6562 113.659 23.8772 113.423 23.8772H112.777C112.519 23.8772 112.303 23.6562 112.303 23.3906V13.9678Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.562 8.81378C123.562 8.59235 123.756 8.39343 123.971 8.39343H124.81C125.047 8.39343 125.219 8.59235 125.219 8.81378V15.2725H133.959V8.81378C133.959 8.59235 134.131 8.39343 134.368 8.39343H135.207C135.423 8.39343 135.617 8.59235 135.617 8.81378V23.4574C135.617 23.6781 135.423 23.8773 135.207 23.8773H134.368C134.131 23.8773 133.959 23.6781 133.959 23.4574V16.7547H125.219V23.4574C125.219 23.6781 125.047 23.8773 124.81 23.8773H123.971C123.756 23.8773 123.562 23.6781 123.562 23.4574V8.81378Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.871 22.6386C144.83 22.6386 146.272 20.8688 146.272 18.7455C146.272 16.6661 144.83 14.9186 142.871 14.9186C140.912 14.9186 139.448 16.6661 139.448 18.7455C139.448 20.8688 140.912 22.6386 142.871 22.6386ZM142.871 13.4369C145.626 13.4369 147.865 15.8699 147.865 18.7455C147.865 21.6651 145.626 24.1206 142.871 24.1206C140.116 24.1206 137.877 21.6651 137.877 18.7455C137.877 15.8699 140.116 13.4369 142.871 13.4369Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.039 13.9678C150.039 13.7244 150.19 13.5694 150.427 13.5694C151.051 13.5694 151.395 14.0562 151.46 14.72C152.063 14.1001 153.096 13.4368 154.538 13.4368C156.024 13.4368 156.82 13.9898 157.466 15.0517C157.595 14.9632 159.016 13.4368 161.018 13.4368C163.902 13.4368 164.591 15.3614 164.591 17.9936V23.3906C164.591 23.6562 164.376 23.8772 164.118 23.8772H163.472C163.214 23.8772 162.998 23.6562 162.998 23.3906V17.9936C162.998 16.091 162.395 14.9189 160.889 14.9189C159.274 14.9189 158.37 16.0028 158.004 16.3124C158.09 16.7549 158.133 17.4629 158.133 17.9936V23.3906C158.133 23.6562 157.918 23.8772 157.66 23.8772H157.014C156.777 23.8772 156.541 23.6562 156.541 23.3906V17.9936C156.541 16.0471 155.916 14.9189 154.302 14.9189C152.536 14.9189 151.632 16.3346 151.632 16.3346V23.3906C151.632 23.6562 151.395 23.8772 151.159 23.8772H150.513C150.255 23.8772 150.039 23.6562 150.039 23.3906V13.9678Z"
      />
      <path
        fill={primary}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.407 17.8384C174.364 16.3342 173.202 14.9189 171.501 14.9189C169.822 14.9189 168.487 16.246 168.294 17.8384H174.407ZM171.501 13.4368C174.105 13.4368 176 15.4055 176 18.0818C176 18.2588 175.979 18.5243 175.957 18.7013C175.936 18.9668 175.72 19.1438 175.505 19.1438H168.272C168.315 20.847 169.714 22.6385 171.523 22.6385C172.835 22.6385 173.654 22.1739 174.213 21.7982C174.558 21.555 174.708 21.4664 174.967 21.8646L175.225 22.2405C175.419 22.5501 175.505 22.6608 175.139 22.9481C174.536 23.4351 173.223 24.121 171.501 24.121C168.487 24.121 166.679 21.6877 166.679 18.7675C166.679 15.8921 168.487 13.4368 171.501 13.4368Z"
      />
    </svg>
  );
};

export default PlusUpLogo;
