import React from 'react';
import styled from '@emotion/styled';

import LoadingSpinner from 'Components/loadingSpinner';

import colors from 'Utils/theme';

const StyledPreLoader = styled.div`
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.white};
  z-index: 666;
`;

const PreLoader = () => {
  return (
    <StyledPreLoader>
      <LoadingSpinner color={colors.inHome.blue} />
    </StyledPreLoader>
  );
};

export default PreLoader;
