import React, { Children } from 'react';
import styled from '@emotion/styled';
// import { useRouter } from 'next/router';

// import useWindowSize from 'Hooks/useWindowSize';

import { minTablet, minDesktop } from 'Utils/variables';
import colors from 'Utils/theme';
import { H4 } from 'Styles/typography';
import { responsiveFont } from 'Styles/helpers';

import Segment from 'Components/segment';
import Row from 'Components/row';
import Column from 'Components/column';
import Subscribe from 'Components/subscribe';

const FooterColumn = styled(Column)`
  display: ${(props) => (props.notmobile ? 'none' : 'block')};

  @media (min-width: ${minTablet}) {
    display: block;
  }
`;

const Heading = styled(H4)`
  ${responsiveFont({ sizes: '16, 20' })};
`;

const List = styled.ul`
  padding-top: 10px;
  padding-bottom: 30px;

  @media (min-width: ${minTablet}) {
    padding-bottom: 50px;
  }
`;

const Item = styled.li`
  ${responsiveFont({ sizes: '16, 18' })};
  position: relative;

  margin-bottom: 20px;

  line-height: (30px/18px);
  font-weight: 400;

  > a,
  > div {
    color: ${colors.inHome.darkGrey};
  }
`;

const Social = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-top: 30px;

  width: 100%;
  max-width: 180px;

  svg {
    margin: 0;
    margin-right: 25px;

    height: 20px;
    width: 20px;
  }

  a:last-child {
    svg {
      margin-right: 0;
    }
  }
`;

const Ccpa = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 34px;
  margin-top: 20px;

  width: 100%;

  line-height: ${34 / 16};
  letter-spacing: -0.017em;

  @media (min-width: ${minTablet}) {
    flex-flow: row wrap;
    margin-bottom: 5px;
  }

  @media (min-width: ${minDesktop}) {
    margin-top: 70px;
    letter-spacing: 0;
  }

  a {
    ${responsiveFont({ sizes: '13,16' })};
    position: relative;
    padding-right: 15px;
    margin-right: 15px;

    color: ${colors.inHome.darkGrey};
    text-decoration: none;
    transition: 200ms color ease-in-out;

    &:hover {
      color: ${colors.walmart.blue};
    }

    @media (min-width: ${minTablet}) {
      &::after {
        content: '';

        position: absolute;
        right: 0;
        top: 50%;
        height: 2px;
        width: 2px;

        background-color: ${colors.inHome.darkGrey};
        border-radius: 50%;
      }
    }

    &:last-child {
      padding: 0;
      margin: 0;

      &::after {
        display: none;
      }
    }
  }
`;

const Bold = styled.b`
  ${responsiveFont({ sizes: '13,16' })};

  margin-right: 23px;
  font-weight: 700;

  color: ${colors.inHome.darkGrey};

  @media (min-width: ${minTablet}) {
    &:after {
      content: ':';
    }
  }

  @media (min-width: ${minDesktop}) {
    margin-right: 27px;
  }
`;

const Copyright = styled.div`
  ${responsiveFont({ sizes: '12,16' })};

  display: flex;
  flex-direction: column;

  line-height: ${22 / 16};
  color: ${colors.inHome.darkGrey};

  @media (min-width: ${minTablet}) {
    flex-direction: row;
    align-items: center;
  }

  @media (min-width: ${minDesktop}) {
    margin: 12px 0;
  }

  div:first-of-type {
    margin-right: 27px;

    @media (min-width: ${minDesktop}) {
      margin-right: 53px;
    }
  }

  a {
    ${responsiveFont({ sizes: '12,16' })};

    text-decoration: none;
    color: ${colors.inHome.darkGrey};
    transition: 200ms color ease-in-out;

    &:hover {
      color: ${colors.walmart.blue};
    }

    &:first-of-type {
      position: relative;
      padding-right: 15px;
      margin-right: 15px;

      &::after {
        content: '';

        position: absolute;
        right: 0;
        top: 50%;
        height: 2px;
        width: 2px;

        background-color: ${colors.inHome.darkGrey};
        border-radius: 50%;
      }
    }
  }
`;

const Footer = ({ children, contact, social, copyright, privacy, ccpa }) => {
  // const { windowWidth } = useWindowSize();
  // const router = useRouter();

  return (
    <Segment container paddingTop="100" paddingTopMobile="80" paddingBottom="130" paddingBottomMobile="60">
      <Row>
        <FooterColumn all={{ width: '10/20' }} mdWide={{ width: '4/20' }} lg={{ width: '4/20', offsetLeft: 1 }} notmobile>
          <Heading>InHome</Heading>

          <List>
            {Children.map(children, (child) => (
              <Item>{child}</Item>
            ))}
          </List>
        </FooterColumn>

        <FooterColumn all={{ width: '100%' }} md={{ width: '10/20' }} mdWide={{ width: '5/20' }} lg={{ width: '4/20' }}>
          <Heading>We’re here to help</Heading>

          <List>
            {Children.map(contact.props.children, (child) => (
              <Item>{child}</Item>
            ))}

            <Item>
              <Social>{social}</Social>
            </Item>
          </List>
        </FooterColumn>

        <FooterColumn all={{ width: '100%' }} md={{ width: '10/20' }} mdWide={{ width: '6/20' }} lg={{ width: '5/20' }}>
          <Heading>Keep in touch</Heading>

          <List>
            <Item>
              <Subscribe inFooter={true} stayInTouch={true} placeholder="Your email" postalCode="00000" eligible={false} leadSource={`website_footer`} />
            </Item>
          </List>
        </FooterColumn>
      </Row>

      <Row>
        <FooterColumn all={{ width: '100%' }} md={{ width: '100%' }} lg={{ width: '18/20', offsetLeft: 1 }}>
          <Ccpa>
            <Bold>California residents</Bold>
            {ccpa}
          </Ccpa>
        </FooterColumn>
      </Row>

      <Row>
        <FooterColumn all={{ width: '100%' }} md={{ width: '100%' }} lg={{ width: '18/20', offsetLeft: 1 }}>
          <Copyright>
            <div>&copy; {copyright}</div>
            <div>{privacy}</div>
          </Copyright>
        </FooterColumn>
      </Row>
    </Segment>
  );
};

export default Footer;
