import React, { useReducer, useCallback, useMemo } from 'react';

import { DispatchContext } from 'Context/DispatchContext';
import { StateContext } from 'Context/StateContext';

import MenuReducer from './reducers/MenuReducer';
import UserReducer from './reducers/UserReducer';
import AddressesReducer from './reducers/AddressesReducer';
import MembershipReducer from './reducers/MembershipReducer';
import SchedulingReducer from './reducers/SchedulingReducer';
import CMSReducer from './reducers/CMSReducer';
import CheckoutReducer from './reducers/CheckoutReducer';
import FeatureFlagReducer from './reducers/FeatureFlagReducer';
import DevicesReducer from './reducers/DevicesReducer';
import deviceSelectionReducer, { getInitialState as deviceSelectionInitialState } from './reducers/DeviceSelectionReducer';

const StoreProvider = ({ children }) => {
  // Combine dispatch function
  const combineDispatch = (...dispatches) => (action) => dispatches.forEach((dispatch) => dispatch(action));

  // Reducers
  const [menuState, menuDispatch] = useReducer(MenuReducer, { inverted: false });
  const [userState, userDispatch] = useReducer(UserReducer, { hasDevice: false });
  const [addressesState, addressesDispatch] = useReducer(AddressesReducer, {});
  const [membershipState, membershipDispatch] = useReducer(MembershipReducer, { canceledMembership: false, existingMembershipData: true });
  const [schedulingState, schedulingDispatch] = useReducer(SchedulingReducer, { reschedule: false });
  const [cmsState, cmsDispatch] = useReducer(CMSReducer, {});
  const [checkoutState, checkoutDispatch] = useReducer(CheckoutReducer, {});
  const [featureFlagState, featureFlagDispatch] = useReducer(FeatureFlagReducer, { featureFlags: [], hasLoaded: false });
  const [devicesState, devicesDispatch] = useReducer(DevicesReducer);
  const [deviceSelectionState, deviceSelectionDispatch] = useReducer(deviceSelectionReducer, deviceSelectionInitialState());

  // Combine states and dispatches. use dispatch as usual => dispatch({type: '', payload: ''});
  const dispatch = useCallback(combineDispatch(menuDispatch, userDispatch, addressesDispatch, membershipDispatch, schedulingDispatch, cmsDispatch, checkoutDispatch, featureFlagDispatch, devicesDispatch, deviceSelectionDispatch), [
    menuDispatch,
    userDispatch,
    addressesDispatch,
    membershipDispatch,
    schedulingDispatch,
    cmsDispatch,
    checkoutDispatch,
    featureFlagDispatch,
    devicesDispatch,
    deviceSelectionDispatch,
  ]);
  const state = useMemo(() => ({ menuState, userState, addressesState, membershipState, schedulingState, cmsState, checkoutState, featureFlagState, devicesState, deviceSelectionState }), [
    menuState,
    userState,
    addressesState,
    membershipState,
    schedulingState,
    cmsState,
    checkoutState,
    featureFlagState,
    devicesState,
    deviceSelectionState,
  ]);

  return (
    <DispatchContext.Provider value={{ dispatch }}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export default StoreProvider;
