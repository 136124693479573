import React from 'react';
import NextLink from 'next/link';
import styled from '@emotion/styled';

import Link from 'Components/link';

import { Button as LDButton } from '@walmart-web/livingdesign-components';

export const StyledButton = styled(LDButton)`
  margin: ${(props) => (props.inMenu ? `0 20px 0 0` : `0 auto`)};
`;

const CallToAction = ({ href = null, inMenu = false, target = null, children, disabled, ...props }) => {
  const inMenuStyle = inMenu ? { margin: '0 20px 0 0' } : { margin: `0 auto` };

  return href ? (
    !target ? (
      <NextLink href={href} passHref>
        <StyledButton variant="primary" isFullWidth size="large" {...props} className={props.className} disabled={disabled} style={{ ...inMenuStyle, ...{ ...(props.style || null) } }}>
          {children}
        </StyledButton>
      </NextLink>
    ) : (
      <Link asLDButton variant="primary" isFullWidth size="large" href={href} {...props} className={props.className} disabled={disabled} targetBlank={target === '_blank'} style={{ ...inMenuStyle, ...{ ...(props.style || null) } }} rel="noreferrer noopener">
        {children}
      </Link>
    )
  ) : (
    <StyledButton variant="primary" isFullWidth size="large" {...props} className={props.className} type={props.type} disabled={disabled} style={{ ...inMenuStyle, ...{ ...(props.style || null) } }}>
      {children}
    </StyledButton>
  );
};

export default CallToAction;
