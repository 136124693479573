const getInitialState = ({ keypadInformation = {}, whereToDeliver = '', homeAccess = {} } = {}) => ({
  keypadInformation,
  whereToDeliver,
  homeAccess,
});

const actionTypes = {
  SET_WHERE_TO_DELIVER: 'SET_WHERE_TO_DELIVER',
  SET_HOME_ACCESS: 'SET_HOME_ACCESS',
  SET_KEYPAD_INFORMATION: 'SET_KEYPAD_INFORMATION',
};

function deviceSelectionReducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_WHERE_TO_DELIVER: {
      return {
        ...state,
        whereToDeliver: action.payload,
      };
    }
    case actionTypes.SET_HOME_ACCESS: {
      return {
        ...state,
        homeAccess: action.payload,
      };
    }
    case actionTypes.SET_KEYPAD_INFORMATION: {
      return {
        ...state,
        keypadInformation: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export { getInitialState, actionTypes };

export default deviceSelectionReducer;
