import React from 'react';
import styled from '@emotion/styled';

import colors from 'Utils/theme';
import { minTablet } from 'Utils/variables';

import CallToAction from 'Components/callToAction';

const StyledStickyFooter = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: ${colors.white};
  z-index: ${(props) => (props.zIndex ? props.zIndex : 90)};
  padding: 18px 20px;
  border-top: 1px solid ${colors.inHome.medGrey};
`;

const StyledCallToAction = styled(CallToAction)`
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: ${minTablet}) {
    max-width: 100%;
    margin: 0 auto;
  }
`;

const StickyFooter = ({ ctaText, href, onClick, zIndex }) => {
  return (
    <StyledStickyFooter zIndex={zIndex}>
      <StyledCallToAction href={href} target="_blank" onClick={onClick}>
        {ctaText}
      </StyledCallToAction>
    </StyledStickyFooter>
  );
};

export default StickyFooter;
