export const defaultFetch = async (url, options = null, redirect = true) => {
  const res = await fetch(url, options);
  const jwt = await res.headers.get('x-csrf-jwt');
  const data = await res.json();
  const status = res.status;
  const success = res.ok;

  if (status === 401 && redirect) {
    return window.location.assign(`${process.env.NEXT_PUBLIC_LOGOUT_URL}${window.location.pathname}`);
  }

  if (success && status < 400) {
    return { data, jwt };
  } else {
    throw { errors: data.errors, status, data };
  }
};
