const Reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SCHEDULING':
      return {
        ...state,
        scheduling: action.payload,
      };
    case 'SET_RESCHEDULE':
      return {
        ...state,
        reschedule: action.payload,
      };
    case 'SET_INSTALL_TIMES':
      return {
        ...state,
        installTimes: action.payload,
      };
    case 'SET_PROMO_CODE':
      return {
        ...state,
        promoCode: action.payload,
      };
    case 'UPDATE_APPOINTMENT':
      return {
        ...state,
        appointment: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
