const CheckoutReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_ADDRESS':
      return {
        ...state,
        selectedAddress: action.payload,
      };
    case 'SET_IS_ANNUAL_PLAN':
      return {
        ...state,
        isAnnualPlan: action.payload,
      };
    case 'SET_SELECTED_CC':
      return {
        ...state,
        selectedCc: action.payload,
      };
    case 'SET_ORDER_COMPLETE':
      return {
        ...state,
        orderComplete: action.payload,
      };
    case 'SET_ORDER_SUMMARY':
      return {
        ...state,
        orderSummary: action.payload,
      };
    default:
      return state;
  }
};

export default CheckoutReducer;
