import React from 'react';
import colors from 'Utils/theme';

const Instagram = ({ fill = colors.inHome.blue }) => {
  return (
    <svg className="instagram" viewBox="0 0 46 46" fill={fill}>
      <path
        d="M33.88,1A12.59,12.59,0,0,1,39,2a12.92,12.92,0,0,1,7,7,12.59,12.59,0,0,1,1,5.09V33.88A12.59,12.59,0,0,1,46,39a12.92,12.92,0,0,1-7,7,12.59,12.59,0,0,1-5.09,1H14.12A12.59,12.59,0,0,1,9,46a12.92,12.92,0,0,1-7-7,12.59,12.59,0,0,1-1-5.09V14.12A12.59,12.59,0,0,1,2,9,12.92,12.92,0,0,1,9,2a12.59,12.59,0,0,1,5.09-1Zm3.3,13.12a3.11,3.11,0,0,0,2.28-1,3.18,3.18,0,0,0,.95-2.34,3.24,3.24,0,0,0-3.23-3.23,3.18,3.18,0,0,0-2.34.95,3.11,3.11,0,0,0-1,2.28,3.28,3.28,0,0,0,3.3,3.3ZM24,37.18a12.94,12.94,0,0,0,5.14-1,13.59,13.59,0,0,0,7-7,13.22,13.22,0,0,0,0-10.28,13.59,13.59,0,0,0-7-7,13.22,13.22,0,0,0-10.28,0,13.59,13.59,0,0,0-7,7,13.22,13.22,0,0,0,0,10.28,13.59,13.59,0,0,0,7,7A12.94,12.94,0,0,0,24,37.18Zm0-23.06A9.87,9.87,0,0,1,33.88,24,9.87,9.87,0,0,1,24,33.88,9.87,9.87,0,0,1,14.12,24,9.87,9.87,0,0,1,24,14.12Z"
        transform="translate(-1 -1)"
      />
    </svg>
  );
};

export default Instagram;
